import { useCallback, useEffect, useRef, useState } from "react";
import { useDeleteModelPicMutation, useGetModelPicQuery, useUpdateModelPicMutation } from "../../../state/api/collections";
import Buttoon from "../../Buttoon";
import { avatars } from "../chat/History";
import { AvatarName, useCurrentCollectionId, useCurrentOrganizationId, } from "../../../state/GeneralSlice";
import ConfirmationDialog from "../ConfirmationDialog";
import Loading from "../Loading";
import classNames from "classnames";
import { stringToHash } from "utils/Utils";

const IMG_SIZE = 200;

export default function ModelPicUploader({ save, setting, setValue, onLoaded, onSaved }) {
    const [pic, setPic] = useState();
    const [isLoaded, setLoaded] = useState(false);
    const collId = useCurrentCollectionId();
    const { data: modelPic } = useGetModelPicQuery({ collection_id: collId });

    const [resetPic] = useDeleteModelPicMutation();

    const [isRendering, setRendering] = useState(false);
    const onChange = useCallback((e) => {
        setRendering(true);
        const img = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onloadend = () => {
            const base64 = reader.result;
            setPic(base64);
        }
    }, [])

    const canvasRef = useRef();

    useEffect(() => {
        const img = new Image();
        img.src = pic || modelPic || avatars[AvatarName.VICUNA].icon;
        const context = canvasRef.current.getContext('2d');
        img.onload = () => {
            const w = img.width;
            const h = img.height;
            const m = Math.min(w, h);
            let x, y;
            if (m === h) {
                x = (w - m) / 2;
                y = 0;
            }
            else {
                x = 0;
                y = (h - m) / 2;
            }
            context.drawImage(img, x, y, m, m, 0, 0, IMG_SIZE, IMG_SIZE);
            setValue(stringToHash(img.src));

            setRendering(false);
            if (!isLoaded) {
                setLoaded(true);
                onLoaded?.();
            }
        }
    }, [isLoaded, modelPic, onLoaded, pic, setValue])

    const inputRef = useRef();

    const [show, setShow] = useState(false);
    const handleReset = useCallback(async (confirm) => {
        if (!confirm) return;
        setRendering(true);
        await resetPic({ collection_id: collId });
        setPic(undefined);
    }, [collId, resetPic]);

    const orgId = useCurrentOrganizationId();
    const [updatePic] = useUpdateModelPicMutation();
    const [isSaving, setSaving] = useState(false);
    useEffect(() => {
        if (save) setSaving(true);
    }, [save]);
    useEffect(() => {
        async function saveSetting() {
            if (isSaving) {
                setSaving(false);
                const picture = inputRef.current.files[0];
                if (picture) {
                    const result = await updatePic({ collection_id: collId, params: { current_org_uuid: orgId }, payload: { pic: picture } });
                    const error = result.error ? "Model Pic" : undefined;
                    onSaved?.(error);
                }
                else onSaved?.();
            }
        }
        saveSetting();
    }, [collId, isSaving, onSaved, orgId, pic, updatePic]);

    return <div>
        <label htmlFor="prompt" className="block text-sm font-medium leading-6 mb-2">
            {setting.description}
        </label>
        <div className="flex justify-between items-center content-center sm:gap-x-32">
            <div className="flex gap-x-2 sm:gap-x-8 items-center">
                {isRendering && <Loading size="w-24 h-24" center={false} />}
                <canvas
                    ref={canvasRef}
                    alt="Model pic"
                    width={IMG_SIZE}
                    height={IMG_SIZE}
                    className={classNames("w-24 h-24 rounded-full bg-white", { "hidden": isRendering })}
                />

                <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    ref={inputRef}
                    onChange={onChange}
                    name={setting.endpoint}
                    id={setting.endpoint}
                    style={{ display: 'none' }}
                />

                <Buttoon
                    padding="py-0"
                    className="text-md align-middle"
                    onClick={() => inputRef.current?.click()}
                >
                    Change
                </Buttoon>
            </div>
            <Buttoon
                padding="py-0"
                classNameOverride="bg-orange rounded-md text-xs align-middle h-6 w-28 hover:bg-orange-pastel"
                onClick={() => setShow(true)}
            >
                Reset to Default
            </Buttoon>
            <ConfirmationDialog confirmText="Reset" show={show} setShow={setShow} onClose={handleReset} />
        </div>
    </div>
}