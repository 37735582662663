import { Popover, Transition } from "@headlessui/react";
import { useGetThreadFromUuidQuery, useLazySuggestThreadTitleQuery, useUpdateThreadTitleMutation } from "../../../state/api/threads";
import { PencilIcon, LightBulbIcon, CheckIcon } from "@heroicons/react/24/solid";
import { useHover, useClickAway } from "@uidotdev/usehooks";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCurrentCollectionId, useCurrentOrganizationId } from "../../../state/GeneralSlice";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useGetCollectionFeedbackThreadQuery } from "state/api/collections";

function TitleSuggestion({ uuid, invisible, onClick }) {
    const orgId = useCurrentOrganizationId();
    const [suggestTitle, { data: suggestion, isLoading, isFetching }] = useLazySuggestThreadTitleQuery();

    return <Popover className="">
        {({ open }) => <>
            <Transition
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-12"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-12"
                className="absolute top-full left-4 z-50"
                as="div"
            >
                <Popover.Panel className="z-50 rounded-md border-blue border-2 px-1 pb-1 bg-black">
                    <div className="flex items-center justify-between gap-x-4 text-white">
                        <span className="text-sm">Title Suggestion</span>
                        <Popover.Button
                            as="button"
                            title="Apply"
                            onClick={() => onClick(suggestion)}
                            className={classNames("text-transparent hover:text-blue-lightest stroke-white hover:stroke-green")}
                        >
                            <CheckIcon className="h-5 w-5 z-10" />
                        </Popover.Button>
                    </div>
                    <span>
                        {(isLoading || isFetching) ? "Thinking..." : suggestion}
                    </span>
                </Popover.Panel>
            </Transition>

            <Popover.Button as="div">
                <button
                    title="Suggest"
                    onClick={() => {
                        if (!open) suggestTitle({
                            thread_uuid: uuid,
                            current_org_uuid: orgId
                        });
                    }}
                    className={classNames("text-transparent hover:text-blue-lightest stroke-blue-lightest hover:stroke-white", { "invisible": invisible })}
                >
                    <LightBulbIcon className="h-6 w-6 z-10" />
                </button>
            </Popover.Button>
        </>
        }
    </Popover>
}

export default function Title({ uuid, feedbackThreadId }) {
    const [edit, setEdit] = useState(false);
    const titleRef = useRef();
    const [hoverRef, isHover] = useHover();
    const clickRef = useClickAway(() => setEdit(false));

    const { chat } = useGetThreadFromUuidQuery({ thread_uuid: uuid }, {
        skip: !uuid,
        selectFromResult: ({ data }) => ({
            chat: {
                title: data?.title,
                date: data?.created,
            }
        })
    })
    const collId = useCurrentCollectionId();
    const { feedbacks } = useGetCollectionFeedbackThreadQuery({ collection_id: collId, thread_uuid: feedbackThreadId }, {
        skip: !collId || !feedbackThreadId,
        selectFromResult: ({ data }) => ({
            feedbacks: {
                title: data?.title,
                date: data?.created,
            }
        })
    })
    const [updateTitle] = useUpdateThreadTitleMutation();

    const title = useMemo(() => chat.title || feedbacks.title || "-", [chat, feedbacks]);

    useEffect(() => {
        if (edit) {
            titleRef.current.focus();
            titleRef.current.select();
        }
    }, [edit])

    const submitTitle = useCallback((title) => {
        const t = title || "-";
        updateTitle({ uuid: uuid, title: t });
    }, [updateTitle, uuid]);


    const keyDownWorks = useRef(false);

    const handleSubmit = useCallback((e) => {
        keyDownWorks.current = true;
        if (e.key === 'Enter') {
            setEdit(false);
            submitTitle(e.target.value);
        }
    }, [submitTitle]);

    const date = useMemo(() => new Date(chat.date * 1000 || feedbacks.date * 1000 || 0).toLocaleDateString(), [chat.date, feedbacks.date]);
    const navigate = useNavigate();

    const [text, setText] = useState("");
    useEffect(() => {
        setText(title);
    }, [title])

    /**fix for firefox or similar*/
    const onChange = useCallback((e) => {
        const value = e.target.value;
        setText(value);
        if (!keyDownWorks.current) {
            setEdit(false);
            submitTitle(value);
        }
    }, [submitTitle]);

    return <div className="text-blue-lightest text-2xl flex items-center">
        <div
            ref={(el) => { hoverRef.current = el; clickRef.current = el; }}
            id="title"
            className="w-full flex items-center gap-x-2 select-none relative"
        >
            <button className={classNames("text-blue-lightest flex items-center gap-x-2", { "hover:text-white": !!feedbackThreadId })}
                title={feedbackThreadId ? "To feedbacks overview" : title}
                disabled={!feedbackThreadId}
                onClick={() => navigate("../")}>
                {feedbackThreadId && <ChevronLeftIcon className="w-7 h-7" />}
                {edit && <input
                    ref={titleRef}
                    type="text"
                    name="title"
                    id="title"
                    disabled={!edit}
                    onKeyDown={handleSubmit}
                    value={text}
                    onChange={onChange}
                    //defaultValue={title}
                    className={classNames("w-full text-2xl font-medium border-0 focus:ring-0 bg-blue-dark text-white -z-10")}
                    placeholder="Title"
                />}
                {!edit && <p className={classNames("max-w-2/3 text-2xl font-medium py-2 truncate block", { "px-3": !feedbackThreadId })}>{title}</p>}
            </button>
            <button
                title="Edit"
                onClick={() => setEdit(true)}
                className={classNames("text-transparent hover:text-blue-lightest stroke-blue-lightest hover:stroke-white", { "invisible": feedbackThreadId || !isHover || edit })}
            >
                <PencilIcon className="h-6 w-6 z-10" />
            </button>
            <TitleSuggestion
                uuid={uuid}
                invisible={feedbackThreadId || !isHover || edit}
                onClick={(suggestion) => submitTitle(suggestion)}
            />
        </div>
        <div className="text-lg">{date}</div>
    </div>
}
