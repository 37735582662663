import Webcam from "react-webcam";
import { useRef, useCallback, useState, useEffect } from "react";
import Buttoon from "components/Buttoon";
import Modal from "../Modal";
import ComboBox from "components/ComboBox";
import Separator from "components/Separator";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import Editor from "./lexical/Editor";
import Badge from "./Badge";
import { INSERT_IMAGE_COMMAND } from "./lexical/nodes/image/ImagesPlugin";
import { useGetExcelMockQuery } from "state/api/utils";

const videoConstraints = {
    width: 1920,
    height: 1080,
    //facingMode: { exact: "environment" }
    //facingMode: "user"
};

export default function Laboratory() {
    const [deviceId, setDeviceId] = useState("");
    const [devices, setDevices] = useState([]);

    //Desktop
    const refeshDevices = useCallback(() => {
        navigator.mediaDevices
            .enumerateDevices()
            .then(mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")));
    }, []);

    useEffect(
        () => refeshDevices(),
        [refeshDevices]
    );
    //console.log(devices);

    useEffect(() => {
        if (!deviceId) setDeviceId(devices.length > 0 ? devices[0].deviceId : "");
    }, [deviceId, devices])

    /*
    return (
        <>
            {devices.map((device, key) => (
                <div>
                    <Webcam audio={false} videoConstraints={{ deviceId: device.deviceId }} className="bg-white" />
                    {device.label || `Device ${key + 1}`}
                </div>
            ))}
        </>
    );
                */

    const webcamRef = useRef(null);
    const [img, setImg] = useState();
    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            console.log(imageSrc);
            setImg(imageSrc);
        },
        [webcamRef]
    );

    const [show, setShow] = useState(false);
    //console.log(deviceId);


    const editorRef = useRef();
    const onClick = () => {
        editorRef.current.dispatchCommand(INSERT_IMAGE_COMMAND, {
            text: "anodo",
            src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQA0dNHQFa3RAx4rKI7njb7BWDsya11kaxtp198Uuz2lR3Ib4ylCk4xkS9at-nR2JORwgM&usqp=CAU"
        });
    };

    /*
    const data = [
        [{ value: "Vanilla" }, { value: "Chocolate" }],
        [{ value: "Strawberry" }, { value: "Cookies" }],
    ];
    */

    const { data } = useGetExcelMockQuery();

    return <div className="py-2 mx-auto min-w-7xl max-w-7xl h-full flex flex-col">
        <Buttoon onClick={() => setShow(true)}>Take a Pic</Buttoon>

        <div className="my-2">
            <Editor className="w-full px-2 py-3 resize-none text-white placeholder-blue-lightest bg-blue-dark rounded-l-md border-0 focus:ring-0 outline-none" editorRef={editorRef} />
            <button onClick={onClick}>COGLIONE</button>
        </div>

        <div className="my-2"></div>


        <Separator className="my-2" />
        <div className="bg-orange overflow-auto h-20 pt-10">
            <Badge color="pink" />
        </div>

        <Separator className="my-2" />

        <Separator className="my-2" />
        <Modal show={show}>
            <div className="flex flex-col gap-y-2">
                <div className="flex justify-between items-end">
                    <ComboBox
                        label="Select Video Input"
                        items={devices.map(d => d.deviceId)}
                        descriptions={devices.map(d => d.label)}
                        initialValue={deviceId}
                        onSelected={setDeviceId}
                    />
                    <button className="text-blue-light hover:text-white" title="Refresh" onClick={refeshDevices}>
                        <ArrowPathIcon className="w-8 h-8 m-1" />
                    </button>
                </div>
                <Separator />

                <div className="bg-blue-light max-w-full w-[1280px] rounded-md flex items-center aspect-video justify-center">
                    {img
                        ? <>
                            <img src={img} alt="Taken pic" />
                        </>
                        : <>
                            {deviceId
                                ? <Webcam
                                    audio={false}
                                    height={720}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    mirrored={true}
                                    width={1280}
                                    videoConstraints={{ ...videoConstraints, deviceId: deviceId }}
                                    className="rounded-md"
                                />
                                : <span className="text-blue-dark text-2xl font-bold">
                                    No video input available
                                </span>
                            }

                        </>
                    }
                </div>
            </div>
            <div className="relative flex items-baseline justify-end mt-2 gap-x-2">
                <Buttoon
                    width="w-16"
                    className="shrink-0"
                    onClick={capture}
                >
                    Take
                </Buttoon>
                <Buttoon
                    type="button"
                    onClick={() => setShow(false)}
                    width="w-16"
                    className="shrink-0"
                >
                    Cancel
                </Buttoon>
            </div>
        </Modal>
    </div>
}