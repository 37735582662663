import DragDropFile from "./DragDropFile";
import Modal from '../Modal';

export default function AddDocumentModal({ show, onClose }) {
    return (
        <Modal show={show} width="lg:w-[50%]" onClose={() => { }}>
            <DragDropFile onClose={onClose} />
        </Modal>
    )
}
