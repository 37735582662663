import { RESET_VALUE, ResetSettingButton, useFetchAndSaveSetting } from "./settingsUtils";

function SettingInput({ type, setting, value, setValue, save, onLoaded, onSaved, converter = (v) => v }) {
    useFetchAndSaveSetting(setting, save, value, setValue, onLoaded, onSaved);

    return <div className="h-full w-full">
        <div className="flex items-center justify-between mb-2">
            <label htmlFor={setting.endpoint} className="block text-sm font-medium leading-6">
                {setting.description}
            </label>
            {setting.reset_button && <ResetSettingButton setting={setting} onReset={() => setValue(RESET_VALUE)} />}
        </div>

        <input
            type={type}
            id={setting.endpoint}
            name={setting.endpoint}
            value={value}
            onChange={(e) => setValue(converter(e.target.value))}
            className="w-full h-9 cursor-default rounded-md bg-blue py-1.5 pl-3 pr-10 text-left text-white shadow-sm sm:text-sm sm:leading-6  border-0 focus:ring-0"
        />
    </div>
}

export const SettingText = (props) => <SettingInput type="text" {...props} />
export const SettingNumber = (props) => <SettingInput type="number" {...props} converter={(v) => Number(v)} />