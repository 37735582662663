import { useCallback, useRef, useState } from 'react';
import AudioRecorder from '../../AudioRecorder';
import Modal from '../Modal';
import { useUploadRecMutation } from '../../../state/api/collections';
import Buttoon from '../../Buttoon';
import { useCurrentCollectionId, useCurrentOrganizationId } from '../../../state/GeneralSlice';

export default function AddVoiceModal({ show, onClose }) {
    const [disabled, setDisabled] = useState(true);
    const collId = useCurrentCollectionId();
    const orgId = useCurrentOrganizationId();

    const [uploadRec] = useUploadRecMutation();
    const recBlob = useRef();

    const handleSubmit = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (recBlob.current) {
            const title = event.target.title.value;
            const audiofile = new File([recBlob.current], "audiofile.webm", { type: "audio/webm" });
            uploadRec({ current_org_uuid: orgId, collection_id: collId, title: title.trim(), rec: audiofile });
        }
        onClose();
    }, [collId, onClose, orgId, uploadRec]);

    return (
        <Modal show={show} onClose={() => { }}>
            <form onSubmit={handleSubmit} className="relative">
                <div className="overflow-hidden rounded-lg border-0 border-dashed border-blue-lightest shadow-sm bg-blue text-white">
                    <label htmlFor="title" className="sr-only">
                        Title
                    </label>
                    <input
                        type="text"
                        name="title"
                        id="title"
                        required
                        className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-blue-lightest focus:ring-0 bg-blue"
                        placeholder="Title"
                    />
                    <div className="border-t-2 border-blue-dark mx-2"></div>
                    <div className="overflow-hidden rounded-lg border-0 border-dashed border-blue-lightest shadow-sm bg-blue text-white p-2">
                        <AudioRecorder
                            onRecordingStarted={() => setDisabled(true)}
                            onRecordingStopped={(url, blob) => recBlob.current = blob}
                            onLoadedMetadata={(duration) => setDisabled(duration < 1.0)}
                        />
                    </div>
                </div>

                <div className="flex items-center justify-end mt-2 gap-x-2">
                    <Buttoon
                        type="submit"
                        disabled={disabled}
                        width="w-16"
                        className="shrink-0"
                    >
                        Add
                    </Buttoon>
                    <Buttoon
                        type="button"
                        onClick={onClose}
                        width="w-16"
                        className="shrink-0"
                    >
                        Cancel
                    </Buttoon>
                </div>
            </form>
        </Modal>
    )
}
