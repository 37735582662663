import { emptyApi as api } from "./emptyApi";

const settingsRtkApi = api.enhanceEndpoints({ addTagTypes: ["settings"] }).injectEndpoints({
    endpoints: (build) => ({
        getSettingsTemplate: build.query<SettingInfo[], CollectionSettingsArgs>({
            query: (args) => ({
                url: `/collections/${args.collectionId}/settings`
            })
        }),
        getDynamicSettingsTemplate: build.mutation<SettingInfo[], DynamicCollectionSettingsArgs>({
            query: (args) => ({
                url: `/collections/${args.collectionId}/settings`,
                method: "POST",
                body: args.body
            })
        }),
        getValuesList: build.query<Record<string, string>, ValuesListArgs>({
            query: (args) => ({
                url: args.url
            })
        }),
        fetchSetting: build.query<any, FetchSettingArgs>({
            query: (args) => ({
                url: args.overrideUrl
                    ? `${args.overrideUrl}/${args.path}`
                    : `/collections/${args.collectionId}/${args.path}`,
                params: args.params,
                body: args.body
            }),
            providesTags: (result, error, args) => [{ type: "settings", id: args.path }]
        }),
        updateSetting: build.mutation<any, UpdateSettingArgs>({
            query: (args) => ({
                url: args.overrideUrl
                    ? `${args.overrideUrl}/${args.path}`
                    : `/collections/${args.collectionId}/${args.path}`,
                method: "PUT",
                params: args.payload,
                body: args.body
            }),
            invalidatesTags: (result, error, args) => [{ type: "settings", id: args.path }]
        }),
        resetSetting: build.mutation<any, FetchSettingArgs>({
            query: (args) => ({
                url: `/collections/${args.collectionId}/${args.path}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, args) => [{ type: "settings", id: args.path }]
        })
    }),
    overrideExisting: false
});

export type ValuesListArgs = {
    url: string;
};

export type CollectionSettingsArgs = {
    collectionId: string;
};

export type DynamicCollectionSettingsArgs = CollectionSettingsArgs & {
    body: string;
};

export type FetchSettingArgs = CollectionSettingsArgs & {
    path: string;
    params?: any;
    body?: any;
    overrideUrl?: string;
};

export type UpdateSettingArgs = FetchSettingArgs & {
    payload?: {
        value?: string;
    };
    body?: any;
};

export type SettingInfo = {
    type: string;
    reset_button: boolean;
    description: string;
    endpoint: string;
    values_list_endpoint?: string;
    lower_bound?: number;
};

export default settingsRtkApi;
export const {
    useGetSettingsTemplateQuery,
    useGetDynamicSettingsTemplateMutation,
    useGetValuesListQuery,
    useFetchSettingQuery,
    useUpdateSettingMutation,
    useResetSettingMutation
} = settingsRtkApi;
