import { RESET_VALUE, ResetSettingButton, useFetchAndSaveSetting } from "./settingsUtils";
import { Switch } from "@headlessui/react";
import classNames from "classnames";

export default function SettingBoolean({ setting, save, value, setValue, onLoaded, onSaved }) {
    useFetchAndSaveSetting(setting, save, value, setValue, onLoaded, onSaved);

    return <div className="h-full w-full">
        <div className="flex items-center justify-between mb-2">
            <label htmlFor="prompt" className="block text-sm font-medium leading-6">
                {setting.description}
            </label>
            <Switch
                id={setting.endpoint}
                checked={value}
                onChange={setValue}
                className={classNames(
                    value ? 'bg-blue-light' : 'bg-blue-dark',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-light focus:ring-offset-2'
                )}
            >
                <span
                    aria-hidden="true"
                    className={classNames(
                        value ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                />
            </Switch>
            {setting.reset_button && <ResetSettingButton setting={setting} onReset={() => setValue(RESET_VALUE)} />}
        </div>
    </div>
}