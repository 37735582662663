import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
    tasks: {}
};

export const tasksSlice = createSlice({
    name: 'tasks',
    initialState: initialState,
    reducers: {
        updateTaskStatus: (state, action) => {
            const id = action.payload.documentId;
            const taskId = action.payload.taskId;
            const status = action.payload.status;
            const progress = action.payload.progress;

            if (!state.tasks[id]) state.tasks[id] = { progress: 0.0, completed: false };
            if (taskId) state.tasks[id].taskId = taskId;
            if (status) state.tasks[id].status = status;
            if (progress) {
                state.tasks[id].progress = progress;
                if (progress >= 1.0) state.tasks[id].completed = true;
            }
        },
        removeTask: (state, action) => {
            const id = action.payload;
            delete state.tasks[id];
        },
        clearTasks: (state) => {
            state.tasks = {};
        }
    },
});

export default tasksSlice.reducer;

export const { updateTaskStatus, removeTask, clearTasks } = tasksSlice.actions;

const documentTaskSelector = (state, documentId) => state[tasksSlice.name].tasks[documentId] || { progress: 0.0, completed: false };

const completedTasksSelector = (state) => {
    const tasks = state[tasksSlice.name].tasks;
    const keys = Object.keys(tasks);
    const completed = [];
    keys.forEach((k) => {
        const status = tasks[k].status;
        if (status === "ERROR" || status === "REVOKED") completed.push(k);
    })
    return completed;
}

const workingSelector = (state) => {
    const tasks = state[tasksSlice.name].tasks;
    const keys = Object.keys(tasks);
    for (let i = 0; i < keys.length; i++) {
        if (!tasks[keys[i]].completed) return true;
    }
    return false;
}

export function useDocumentTask(documentId) {
    return useSelector((state) => documentTaskSelector(state, documentId));
}

export function useCompletedTasks() {
    return useSelector(completedTasksSelector);
}

export function useWorkInProgress() {
    return useSelector(workingSelector);
}