import { useCurrentCollectionId } from "state/GeneralSlice"
import { useGetDynamicSettingsTemplateMutation, useGetSettingsTemplateQuery } from "state/api/settings"
import Loading from "../Loading";
import Buttoon from "components/Buttoon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import _ from "lodash";
import { withCollectionPrivilegesRequired } from "utils/Privileges";
import ReactRouterPrompt from "react-router-prompt";
import ConfirmationDialog from "../ConfirmationDialog";
import SettingsContainer from "./SettingsContainer";

function Settings() {
    const collId = useCurrentCollectionId();
    const { data: settings, isFetching } = useGetSettingsTemplateQuery({ collectionId: collId });

    const [getSettingsTemplate, { data: newSettings, isFetching: isUpdating }] = useGetDynamicSettingsTemplateMutation();
    const template = useMemo(() => newSettings || settings, [newSettings, settings]);

    const [values, setValues] = useState();
    const onStateChanged = useCallback((state) => {
        setValues(state.state.contents);
    }, []);

    const [isLoaded, setLoaded] = useState(false);

    const [triggerChange, setTriggerChange] = useState(false);
    const onChange = useCallback(() => {
        setTriggerChange(true);
    }, []);
    useEffect(() => {
        if (triggerChange) {
            setTriggerChange(false);
            setLoaded(false);
            getSettingsTemplate({ collectionId: collId, body: values });
        }
    }, [collId, getSettingsTemplate, triggerChange, values])


    const isLoading = useMemo(() => isFetching || isUpdating || !isLoaded, [isFetching, isLoaded, isUpdating]);

    const initialSettings = useRef();
    const cloneSettings = useCallback(() => {
        //console.log("CLONE");
        initialSettings.current = _.cloneDeep(values);
    }, [values]);

    useEffect(() => {
        if (!isLoading && !initialSettings.current) {
            cloneSettings();
        }
    }, [cloneSettings, isLoading]);

    const [isSaving, setSaving] = useState(false);
    const [savingStatus, setSavingStatus] = useState(undefined);
    const savingCompleted = useMemo(() => savingStatus !== undefined, [savingStatus]);
    const savingSuccedeed = useMemo(() => savingStatus === true, [savingStatus]);

    useEffect(() => {
        if (savingCompleted) {
            setSaving(false);
            cloneSettings();
        }
    }, [cloneSettings, savingCompleted]);

    useEffect(() => {
        if (savingCompleted) {
            const timeout = setTimeout(() => setSavingStatus(undefined), 3000);
            return () => clearTimeout(timeout);
        }
    }, [savingCompleted]);

    const saveSettings = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        console.log(values);
        console.log(initialSettings.current);
        console.log(getObjectDiff(values, initialSettings.current));
        setSavingStatus(undefined);
        setSaving(true);

    }, [values]);

    const [isInputValid, setInputValid] = useState(true);
    const isInvalid = useMemo(() => isInputValid === false, [isInputValid]);

    return <div className="xl:w-1/2 xl:px-0 w-full mx-auto py-2 h-full px-3">
        <form onSubmit={saveSettings} id="form" className={classNames("flex h-full flex-col items-stretch gap-y-4")}>
            <div className="flex items-center justify-between">
                <div>
                    <h1 className="text-base font-semibold leading-6 text-white">Collection Settings</h1>
                    <p className="mt-2 text-sm text-blue-lightest">
                        Change collection configuration
                    </p>
                </div>
                <div className="flex items-center gap-x-3">
                    {(isSaving || savingCompleted) &&
                        <>
                            {isSaving
                                ? <Loading />
                                : <span className={classNames(
                                    "font-bold",
                                    savingSuccedeed ? "text-green-pastel" : "text-orange-pastel"
                                )}>
                                    {savingSuccedeed ? "Collection settings saved successfully!" : "Something went wrong!"}
                                </span>
                            }
                        </>
                    }
                    <div>
                        <Buttoon type="submit" disabled={isLoading || isSaving || isInvalid || savingSuccedeed}>Save</Buttoon>
                    </div>
                </div>
            </div>
            {isLoading && <Loading />}
            <SettingsContainer
                className={classNames("overflow-y-auto pr-3 border-t-[1px] border-blue-light flex-grow", { "hidden": isLoading })}
                template={template}
                triggerSave={isSaving}
                onChange={onChange}
                onLoadingComplete={() => setLoaded(true)}
                onSavingComplete={setSavingStatus}
                onValidInput={setInputValid}
                onStateChanged={onStateChanged}
                path="root"
            />
        </form>
        <ReactRouterPrompt when={() => !_.isEqual(values, initialSettings.current)}>
            {(props) => <NotSavedPrompt {...props} />}
        </ReactRouterPrompt>
    </div>
}

function getObjectDiff(obj1, obj2) {
    const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(obj2));

    return diff;
}

function NotSavedPrompt({ isActive, onConfirm, onCancel }) {
    const handleConfirm = useCallback((confirm) => {
        if (confirm) onConfirm();
        else onCancel();
    }, [onCancel, onConfirm])

    return <ConfirmationDialog
        show={isActive}
        title="Warning!"
        text={<span>Unsaved changes will be lost.<br />Do you want to proceed anyway?</span>}
        confirmText="Continue"
        onClose={handleConfirm}
    />
}

export default withCollectionPrivilegesRequired((props) => <Settings {...props} />, "adminAccess");