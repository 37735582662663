import { useUpdateDocumentTitleMutation } from "state/api/collections";
import Modal from "../Modal";
import { useCurrentCollectionId, useCurrentOrganizationId } from "state/GeneralSlice";
import { useCallback, useEffect, useRef } from "react";
import Buttoon from "components/Buttoon";

export default function EditTitleModal({ show, setShow, document }) {
    const collId = useCurrentCollectionId();
    const orgId = useCurrentOrganizationId();
    const [updateTitle] = useUpdateDocumentTitleMutation();

    const submitTitle = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        const t = event.target.title.value || "-";
        updateTitle({
            doc_uid: document.filename,
            collection_id: collId,
            params: {
                current_org_uuid: orgId,
            },
            body: {
                title: t.trim(),
            }
        })
        setShow(false);
    }, [collId, document.filename, orgId, setShow, updateTitle]);

    const ref = useRef();
    useEffect(() => {
        ref.current.focus();
        ref.current.select();
    }, []);

    return <Modal show={show}>
        <form onSubmit={submitTitle}>
            <div className="text-white mb-3">
                <div className="mb-2">
                    Renaming the document title will reschedule the embedding process
                </div>
                <div className="flex items-center gap-x-1">
                    <input
                        type="text"
                        name="title"
                        id="title"
                        ref={ref}
                        defaultValue={document.title}
                        className="border-0 focus:ring-0 bg-blue w-full m-0 text-sm w-full font-medium rounded-md placeholder:text-blue-lightest"
                        placeholder="Title"
                    />
                    <span>.{document.filetype}</span>
                </div>
            </div>
            <div className="relative flex items-baseline justify-end mt-2 gap-x-2">
                <Buttoon
                    type="submit"
                    width="w-16"
                    className="shrink-0"
                >
                    Update
                </Buttoon>
                <Buttoon
                    type="button"
                    onClick={() => setShow(false)}
                    width="w-16"
                    className="shrink-0"
                >
                    Cancel
                </Buttoon>
            </div>
        </form>
    </Modal>
}