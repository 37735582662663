import { useCallback, useEffect, useMemo, useState } from "react";
import { useCurrentCollectionId, useCurrentOrganizationId } from "state/GeneralSlice"
import { useAddAssociatedCollectionMutation, useGetAllCollectionsQuery, useGetAssociatedCollectionsQuery, useRemoveAssociatedCollectionMutation } from "state/api/collections";

function CollectionCheckbox({ collection, value, setValue }) {
    const updateSelection = useCallback((e) => {
        if (e.target.checked) setValue(prev => [...prev, collection.uuid]);
        else setValue(prev => prev.filter(v => v !== collection.uuid));
    }, [collection.uuid, setValue])

    return <div className="w-1/2 flex gap-x-1 items-center">
        <input
            name={collection.name}
            id={collection.uuid}
            type="checkbox"
            checked={value.includes(collection.uuid)}
            onChange={updateSelection}
            className="h-4 w-4 rounded border-blue-light text-blue-light bg-blue-dark focus:ring-0"
        />
        <label htmlFor="prompt" className="font-normal text-xs truncate pr-2">
            {collection.name}
        </label>
    </div>
}

export default function CollectionAssociation({ save, value, setValue, onLoaded, onSaved }) {
    const [isLoaded, setLoaded] = useState(false);
    const orgId = useCurrentOrganizationId();
    const { data } = useGetAllCollectionsQuery({ org: orgId });

    const collId = useCurrentCollectionId();
    const collections = useMemo(() => data ? data.filter(c => c.uuid !== collId) : [], [collId, data]);

    const { data: associated } = useGetAssociatedCollectionsQuery({ collection_id: collId });
    useEffect(() => {
        if (associated) {
            setValue([...associated]);
        }
    }, [associated, setValue]);

    useEffect(() => {
        if (associated) {
            if (!isLoaded) {
                setLoaded(true);
                onLoaded?.();
            }
        }
    }, [associated, isLoaded, onLoaded])

    const [addAssociation] = useAddAssociatedCollectionMutation();
    const [removeAssociation] = useRemoveAssociatedCollectionMutation();
    const [isSaving, setSaving] = useState(false);
    useEffect(() => {
        if (save) setSaving(true);
    }, [save]);
    useEffect(() => {
        async function saveSetting() {
            if (isSaving) {
                setSaving(false);
                let error = undefined;

                const toAdd = value.filter(v => !associated.includes(v));
                for (let i = 0; i < toAdd.length; i++) {
                    const result = await addAssociation({ collection_id: collId, params: { current_org_uuid: orgId, cross_retrieve_collection_id: toAdd[i] } })
                    error = result.error ? "Associated Collections" : undefined;
                }

                const toRemove = associated.filter(v => !value.includes(v));
                for (let i = 0; i < toRemove.length; i++) {
                    const result = await removeAssociation({ collection_id: collId, cross_retrieve_collection_id: toRemove[i], params: { current_org_uuid: orgId } })
                    error = result.error ? "Associated Collections" : undefined;
                }

                onSaved?.(error);
            }
        }
        saveSetting();
    }, [addAssociation, associated, collId, isSaving, onSaved, orgId, removeAssociation, value])

    return <div className="w-full">
        <label htmlFor="prompt" className="block text-sm font-medium leading-6 mb-2">
            Associated Collections
        </label>
        <div className="flex flex-wrap gap-y-1">
            {collections.map(c => <CollectionCheckbox key={c.uuid} collection={c} value={value} setValue={setValue} />)}
        </div>
    </div>
}