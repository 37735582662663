import { useCallback } from "react";
import Modal from "../Modal";
import TaskViewer from "./TaskViewer";
import { useCurrentCollectionId } from "state/GeneralSlice";
import { useRemoveDocumentsInUploadMutation } from "state/api/collections";
import Buttoon from "components/Buttoon";
import { CogIcon } from "@heroicons/react/24/solid";
import { removeTask, useCompletedTasks } from "state/TasksSlice";
import { useDispatch } from "react-redux";

export default function TasksModal({ tasks, show, setShow }) {
    const collId = useCurrentCollectionId();

    const [removeUpload] = useRemoveDocumentsInUploadMutation();
    const completed = useCompletedTasks();
    const dispatch = useDispatch();
    const clearAll = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        completed.forEach((el) => {
            removeUpload({ collection_id: collId, doc_uid: el });
            dispatch(removeTask(el));
        });
    }, [collId, completed, dispatch, removeUpload]);


    return <Modal width="lg:w-[50%]" show={show} setShow={setShow}>
        {tasks.length === 0
            ? <div className="text-gray-dark flex flex-col justify-center items-center">
                <CogIcon className="h-28 w-28 opacity-20" />
                <span className="opacity-40 text-lg">Nothing to show</span>
            </div>
            : <TaskViewer files={tasks} />
        }
        <form onSubmit={clearAll} className="text-center mt-4">
            <div className="flex items-center justify-end mt-2 gap-x-2">
                <Buttoon
                    type="submit"
                    disabled={completed.length === 0}
                    width="w-16"
                    className="shrink-0"
                >
                    Clear All
                </Buttoon>
                <Buttoon
                    type="button"
                    onClick={() => setShow(false)}
                    width="w-16"
                    className="shrink-0"
                    disabled={false}
                >
                    Close
                </Buttoon>
            </div>
        </form>
    </Modal>
}