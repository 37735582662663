import classNames from "classnames";
import SettingComponent from "./SettingsComponent";
import { useState, useEffect, useCallback, useMemo } from "react";
import { ComponentsMap } from "./SettingComponentesMap";
import _ from "lodash";
import { RESET_VALUE } from "./settingsUtils";

export default function SettingsContainer({ template, path, onChange, onStateChanged, onLoadingComplete, onSavingComplete, onValidInput, triggerSave = false, className, Renderer = ContainerRender, ...props }) {
    const validInputs = useMemo(() => template ? template.filter(t => Object.keys(ComponentsMap).includes(t.type)) : undefined, [template]);

    const [state, setState] = useState([]);
    const getValue = useCallback((endpoint) => {
        const setting = state.find(el => el.endpoint === endpoint);
        if (setting.value === undefined) return RESET_VALUE;
        else return setting.value;
    }, [state]);

    useEffect(() => {
        if (onStateChanged) {
            const obj = {
                key: path,
                state: { contents: [...state] }
            };
            onStateChanged(obj);
        }
    }, [onStateChanged, path, state])

    useEffect(() => {
        if (template) {
            setState(prev => {
                const existingEndpoints = prev.map(el => el.endpoint);
                const clone = _.cloneDeep(template);

                for (let i = 0; i < template.length; i++) {
                    if (existingEndpoints.includes(template[i].endpoint)) {
                        clone[i].value = prev.find(el => el.endpoint === template[i].endpoint).value;
                    }
                }

                return clone;
            });
        }
    }, [template]);

    const [isLoaded, setLoaded] = useState([false]);
    const isLoading = useMemo(() => isLoaded.some((v) => !v), [isLoaded]);
    const isChanged = useMemo(() => {
        const endpoints = state.map(el => el.endpoint);
        return validInputs && validInputs.every(el => endpoints.includes(el.endpoint));
    }, [state, validInputs]);
    const isReady = useMemo(() => isChanged && validInputs && validInputs.length === isLoaded.length, [isChanged, isLoaded.length, validInputs]);

    //console.log("loaded", path, isLoaded);

    useEffect(() => {
        if (validInputs && isChanged) {
            const arr = new Array(validInputs.length);
            for (let i = 0; i < arr.length; i++) {
                /*
                if (validInputs[i].type === "picture-picker") {
                    arr[i] = true;
                    continue;
                }
                */
                if (validInputs[i].contents) {
                    arr[i] = false;
                    continue;
                }
                if (!validInputs[i].endpoint) {
                    arr[i] = true;
                    continue;
                }
                if (state.find(el => el.endpoint === validInputs[i].endpoint).value !== undefined) {
                    arr[i] = true;
                }
                else {
                    arr[i] = false;
                }
            }
            setLoaded(arr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChanged, validInputs]);

    const updateLoading = useCallback((idx) => {
        setLoaded((prev) => {
            const tmp = [...prev];
            tmp[idx] = true;
            return tmp;
        });
    }, []);

    useEffect(() => {
        if (!isLoading) {
            onLoadingComplete?.();
        }
    }, [isLoading, onLoadingComplete]);

    //**************************
    const [isSaving, setSaving] = useState(false);
    const [savingStatus, setSavingStatus] = useState([undefined]);
    const savingCompleted = useMemo(() => savingStatus.every((el) => el !== undefined), [savingStatus]);
    const savingSuccedeed = useMemo(() => savingStatus.every((el) => el === true), [savingStatus]);
    const onSaved = useCallback((idx, error) => {
        setSavingStatus((prev) => {
            const tmp = [...prev];
            tmp[idx] = error || true;
            return tmp;
        });
    }, []);

    const saveSettings = useCallback(() => {
        const arr = new Array(validInputs?.length || 0);
        arr.fill(undefined);
        setSavingStatus(arr);
        setSaving(true);
    }, [validInputs?.length]);

    useEffect(() => {
        if (triggerSave) {
            saveSettings();
        }
    }, [saveSettings, triggerSave])

    useEffect(() => {
        if (savingCompleted) setSaving(false);
    }, [savingCompleted]);

    useEffect(() => {
        if (savingCompleted) {
            onSavingComplete?.(savingSuccedeed);
        }
    }, [onSavingComplete, savingCompleted, savingSuccedeed]);

    //**************************
    const [isInputValid, setInputValid] = useState([]);
    const onValid = useCallback((idx, value) => {
        setInputValid(prev => {
            const tmp = [...prev];
            tmp[idx] = value;
            return tmp;
        })
    }, []);
    const isValid = useMemo(() => !isInputValid.some(el => el === false), [isInputValid]);

    useEffect(() => {
        onValidInput?.(isValid);
    }, [isValid, onValidInput])

    return <Renderer
        contents={validInputs}
        isReady={isReady}
        isLoading={isLoading}

        onChange={onChange}
        onLoaded={updateLoading}

        isInputValid={isInputValid}
        onValid={onValid}

        state={state}
        setState={setState}
        getValue={getValue}

        isSaving={isSaving}
        onSaved={onSaved}

        className={className}
        {...props}
    />
}

function ContainerRender({ contents, isReady, isLoading, onChange, onLoaded, isInputValid, onValid, state, setState, getValue, isSaving, onSaved, className, ...props }) {
    return <div className={classNames("flex h-full flex-col gap-y-4", { "hidden": false }, className)}>
        {isReady && contents.map((setting, idx) => <SettingComponent
            key={idx}
            setting={setting}
            value={getValue(setting.endpoint)}
            save={isSaving}
            onChange={onChange}
            isValid={isInputValid[idx] === undefined ? true : isInputValid[idx]}
            allCurrentValues={state} //todo

            idx={idx}
            setValues={setState}
            onLoad={onLoaded}
            onSave={onSaved}
            onValid={onValid}
        />)}
    </div>
}

export function SettingContainerWrapper(props) {
    const { setting, setValues, onLoaded, onChange, setValid, save, onSaved, Renderer, className, others } = props;

    //console.log("wrapper", props, others);

    const onStateChanged = useCallback((state) => {
        setValues(prev => {
            const clone = _.cloneDeep(prev);
            const child = clone.find(el => el.description === state.key);
            _.merge(child, state.state);
            return clone;
        });
    }, [setValues]);

    return <SettingsContainer
        template={setting.contents}
        path={setting.description}
        onLoadingComplete={onLoaded}
        onChange={onChange}
        onValidInput={setValid}
        onStateChanged={onStateChanged}
        triggerSave={save}
        onSavingComplete={onSaved}
        Renderer={Renderer}
        className={className}
        {...others}
    />
}