import React, { } from "react";
import TaskPoller from "../TaskPoller";

export default function TaskViewer({ files, setFiles, onDelete }) {
    const uploading = !!setFiles;

    return (
        <div>
            {files && files.length > 0 &&
                <div className="px-0 pt-0 w-full">
                    {files && Object.values(files).map((item, idx) =>
                        <TaskPoller
                            key={idx}
                            idx={idx}
                            task={item}
                            uploading={uploading}
                            onDelete={onDelete}
                            setFiles={setFiles}
                        />
                    )}
                </div>
            }
        </div>
    )
}