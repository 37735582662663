import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { TrashIcon } from "@heroicons/react/24/solid";
import { useCallback, useState } from "react";
import { useHover } from "@uidotdev/usehooks";
import { useDeleteThreadMutation } from "../../../state/api/threads";
import ConfirmationDialog from "../ConfirmationDialog";

export function Thread({ element }) {
    const location = useLocation()
    const navigate = useNavigate()
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteThread] = useDeleteThreadMutation()

    const requestDelete = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowConfirmation(true);
    }, []);

    const deleteCallback = useCallback((confirm) => {
        if (!confirm) return;

        deleteThread({ uuid: element.uuid })
        const isCurrentUuid = location.pathname.endsWith(element.uuid);
        if (isCurrentUuid) navigate("/chat")
    }, [deleteThread, element?.uuid, navigate, location])

    const isSelected = location.pathname.endsWith("/chat/" + element.uuid);
    const [hoverRef, isHover] = useHover()
    return <NavLink
        to={"/chat/" + element.uuid}
        className={classNames("text-blue-lightest hover:text-white group flex gap-x-1 rounded p-2 leading-2 text-xs", { "text-white font-bold": isSelected })}
        ref={hoverRef}
    >
        <ChatBubbleLeftRightIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
        <p className="line-clamp-3 w-full">{element.title}</p>
        <TrashIcon className={classNames("fill-blue-lightest hover:fill-white cursor-pointer h-4 w-4 shrink-0", { "invisible": !isHover })} onClick={requestDelete} />
        <ConfirmationDialog show={showConfirmation} setShow={setShowConfirmation} onClose={deleteCallback} />
    </NavLink>
}