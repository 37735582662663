import classNames from "classnames";
import { useNewThreadCallback } from "../../../state/api/threads";
import { useCurrentCollectionId } from "state/GeneralSlice";

const defaultTemplates = require("../../../application.json")

const backgrounds = [
    'bg-orange-pastel',
    'bg-azure-pastel',
    'bg-green-pastel',
    'bg-yellow-pastel',
]

export default function NewChat() {
    const newThreadCallback = useNewThreadCallback();
    const collId = useCurrentCollectionId();

    return <div className="mx-auto max-w-2xl lg:p-2 justify-start flex flex-col items-stretch h-full max-h-full text-blue-lightest">
        <span className="lg:my-auto">
            <h2 className="text-base font-semibold leading-6 text-white">Chats</h2>
            <p className="mt-1 text-sm">
                Get started by selecting a template or start from an empty chat.
            </p>
            <ul className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-blue-lightest py-6 sm:grid-cols-2">
                {defaultTemplates.chatExamples.map((item, itemIdx) => (
                    <li key={itemIdx} className="flow-root">
                        <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 hover:bg-blue" onClick={() => newThreadCallback(collId, item.template)}>
                            <div
                                className={classNames(
                                    backgrounds[itemIdx % backgrounds.length],
                                    'flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg'
                                )}
                            >
                                <div className={"text-blue font-bold text-5xl pb-2"} aria-hidden="true">{itemIdx + 1}</div>
                            </div>
                            <div>
                                <h3 className="text-sm font-medium hover:font-bold">
                                    <button href="#" className="text-white focus:outline-none">
                                        <span className="absolute inset-0" aria-hidden="true" />
                                        <span>{item.title}</span>
                                        <span aria-hidden="true"> &rarr;</span>
                                    </button>
                                </h3>
                                <p className="mt-1 text-sm">{item.template}</p>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            <div className="mt-4 flex">
                <div className="text-blue-lightest text-sm font-medium hover:text-white cursor-pointer" onClick={() => newThreadCallback()}>
                    Or start from an empty chat
                    <span aria-hidden="true"> &rarr;</span>
                </div>
            </div>
        </span>
    </div>
}
