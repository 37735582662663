import { createSlice } from '@reduxjs/toolkit';
import { storeCollectionId, storeOrganizationId } from '../utils/Storage';
import { useSelector } from 'react-redux';
import organizationsRtkApi from './api/organizations';
import collectionsRtkApi from './api/collections';
import { clearTasks } from './TasksSlice';

export const SenderType = {
    USER: "user",
    LLM: "llm"
}

export const AvatarName = {
    HUMAN: "user",
    VICUNA: "llm",
    AGENT1: "agent1",
    AGENT2: "agent2",
    AGENT3: "agent3",
}

export const UserState = {
    LOGGING_IN: "logging_in",
    LOGGING_ERROR: "logging_error",
    LOGGED_IN: "logged_in",
    LOGGED_OUT: "logged_out"
}

const initialState = {
    userState: UserState.LOGGED_IN,
    loaded: false,
    currentUserId: undefined,
    currentOrganizationId: undefined,
    currentCollectionId: undefined,
    isOrganizationIdValid: false,
    isCollectionIdValid: false,
};

export const generalSlice = createSlice({
    name: 'general',
    initialState: initialState,
    reducers: {
        setCurrentUserId: (state, action) => { state.currentUserId = action.payload },
        setCurrentOrganizationId: (state, action) => {
            const id = action.payload;
            storeOrganizationId(id);
            state.currentOrganizationId = id

            if (state.loaded) {
                storeCollectionId(null);
                state.currentCollectionId = null;
                import('../utils/Session').then(({ loadCollectionId }) => {
                    loadCollectionId();
                });
            }
        },
        setCurrentCollectionId: (state, action) => {
            const id = action.payload;
            storeCollectionId(id);
            state.currentCollectionId = id;
            import('./store').then(({ default: store }) => {
                store.dispatch(clearTasks());
            });
        },
        setLoaded: (state, action) => {
            state.loaded = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(organizationsRtkApi.endpoints.getOrganization.matchFulfilled, (state, action) => {
                state.isOrganizationIdValid = !!action.payload.name;
            })
            .addMatcher(organizationsRtkApi.endpoints.getOrganization.matchRejected, (state) => {
                state.isOrganizationIdValid = false;
            })
            .addMatcher(collectionsRtkApi.endpoints.getCollectionInfo.matchFulfilled, (state, action) => {
                state.isCollectionIdValid = !!action.payload.name;
            })
            .addMatcher(collectionsRtkApi.endpoints.getCollectionInfo.matchRejected, (state) => {
                state.isCollectionIdValid = false;
            })
    }
});

export const userStateSelector = (state) => state[generalSlice.name].userState;
const currentUserIdSelector = (state) => state[generalSlice.name].currentUserId;
export const currentOrganizationIdSelector = (state) => state[generalSlice.name].currentOrganizationId;
export const currentCollectionIdSelector = (state) => state[generalSlice.name].currentCollectionId;
const isOrganizationIdValidSelector = (state) => state[generalSlice.name].isOrganizationIdValid;
const isCollectionIdValidSelector = (state) => state[generalSlice.name].isCollectionIdValid;

export const { setCurrentUserId, setCurrentOrganizationId, setCurrentCollectionId, setLoaded } = generalSlice.actions;

export default generalSlice.reducer;

export function useCurrentUserId() {
    return useSelector(currentUserIdSelector);
}

export function useCurrentOrganizationId() {
    return useSelector(currentOrganizationIdSelector);
}

export function useCurrentCollectionId() {
    return useSelector(currentCollectionIdSelector);
}

export function useIsOrganizationIdValid() {
    return useSelector(isOrganizationIdValidSelector);
}

export function useIsCollectionIdValid() {
    const orgValid = useIsOrganizationIdValid()
    const collValid = useSelector(isCollectionIdValidSelector);
    return orgValid && collValid;
}