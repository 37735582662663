import ComboBox from "components/ComboBox";
import { useGetValuesListQuery } from "state/api/settings";
import { RESET_VALUE, ResetSettingButton, useFetchAndSaveSetting } from "./settingsUtils";
import { useEffect, useCallback, useRef } from "react";
import { useCurrentOrganizationId } from "state/GeneralSlice";

function Tools({ setting, onReset }) {
    return <>
        {setting.reset_button
            ? <div className="flex flex-grow justify-end items-center mb-2">
                <ResetSettingButton setting={setting} onReset={onReset} />
            </div>
            : <></>
        }
    </>
}

function PlainComboBox({ setting, save, value, setValue, onChange, onLoaded, onSaved, choices = [] }) {
    useFetchAndSaveSetting(setting, save, value, setValue, onLoaded, onSaved);

    const requestTrigger = useRef(false);
    const triggerChange = useCallback((v) => {
        setValue(v);
        requestTrigger.current = true;
    }, [setValue])

    useEffect(() => {
        if (requestTrigger.current) {
            requestTrigger.current = false;
            onChange();
        }
    }, [onChange, value]);

    return <>
        {choices && <ComboBox
            label={setting.description}
            name={setting.endpoint}
            initialValue={value}
            items={Object.keys(choices)}
            descriptions={Object.values(choices)}
            onSelected={triggerChange}
            tools={<Tools setting={setting} onReset={() => { setValue(RESET_VALUE) }} />}
        />}
    </>
}

export default function SettingComboBox(props) {
    const { data: choices } = useGetValuesListQuery({ url: props.setting.values_list_endpoint });

    return <PlainComboBox {...props} choices={choices} />
}

export function PipelineComboBox(props) {
    const orgId = useCurrentOrganizationId();
    const { data: choices } = useGetValuesListQuery({ url: `/organizations/${orgId}/pipeline-types` });
    return <PlainComboBox {...props} choices={choices} />
}