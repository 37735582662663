import { useMemo } from "react";
import { useGetAllCollectionsQuery } from "state/api/collections";
import { useGetCurrentUserOrganizationsQuery } from "state/api/organizations";

export const TOTEM_ORGANIZATION_NAME = "DO-IBC";
export const TOTEM_COLLECTION_ASM = "ASM"
export const TOTEM_COLLECTION_PRODIGY_MP = "PRODIGY-MP"

function search(list, item) {
    for (let i = 0; i < list.length; i++) {
        if (list[i].name === item) return list[i].uuid;
    }
    return undefined;
}

function useCollectionId(collectionName) {
    const orgId = useDoIbcOrganizationId();
    const { data, isFetching, isLoading, isError } = useGetAllCollectionsQuery({ org: orgId }, { skip: !orgId });
    return useMemo(() => {
        if (!data || isFetching || isLoading || isError) return undefined;
        return search(data, collectionName);
    }, [collectionName, data, isError, isFetching, isLoading]);
}

//**************************
export function useDoIbcOrganizationId() {
    const { data, isFetching, isLoading, isError } = useGetCurrentUserOrganizationsQuery();
    return useMemo(() => {
        if (isFetching || isLoading || isError) return undefined;
        return search(data, TOTEM_ORGANIZATION_NAME);
    }, [data, isError, isFetching, isLoading]);
}

export function useAsmCollectionId() {
    return useCollectionId(TOTEM_COLLECTION_ASM);
}

export function useProdigyMpCollectionId() {
    return useCollectionId(TOTEM_COLLECTION_PRODIGY_MP);
}