import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Human from './../../../images/human.jpeg';
import Vicuna from './../../../images/vicuna.jpeg';
import Agent1 from './../../../images/agent1.jpeg';
import Agent2 from './../../../images/agent2.jpeg';
import Agent3 from './../../../images/agent3.jpeg';
import { ClipboardDocumentListIcon, ClipboardDocumentIcon, BookOpenIcon } from '@heroicons/react/24/outline'
import { HandThumbUpIcon, HandThumbDownIcon, TrashIcon } from '@heroicons/react/24/solid'
import { AvatarName, SenderType, useCurrentCollectionId } from "../../../state/GeneralSlice";
import ShowSources from "./ShowSources";
import { useCopyAsTableQuery, useDeleteMessageMutation, useGetThreadFromUuidQuery, useKillThreadMutation } from "../../../state/api/threads";
import SendFeedbackModal from "./SendFeedbackModal";
import classNames from "classnames";
import ShowFeedbackModal from "./ShowFeedbackModal";
import { useGetCollectionFeedbackThreadQuery, useGetModelNameQuery, useGetModelPicQuery } from "../../../state/api/collections";
import ArchiveFeedbackButton from "../feedbacks/ArchiveFeedbackButton";
import { useGetUserDetailsQuery } from "state/api/users";
import ConfirmationDialog from "../ConfirmationDialog";
import Loading from "../Loading";
import ScrollToBottom from 'react-scroll-to-bottom';
import MarkdownMessage from "../markdown/MarkdownMessage";

export const avatars = {
    [AvatarName.HUMAN]: {
        textColor: "text-azure-pastel",
        icon: Human
    },
    [AvatarName.VICUNA]: {
        textColor: "text-orange-pastel",
        icon: Vicuna
    },
    [AvatarName.AGENT1]: {
        textColor: "text-green-pastel",
        icon: Agent1
    },
    [AvatarName.AGENT2]: {
        textColor: "text-yellow-pastel",
        icon: Agent2
    },
    [AvatarName.AGENT3]: {
        textColor: "text-pink-pastel",
        icon: Agent3
    },
}

const SendMessageFeedback = ({ item, hasFeedback, checkFeedbacks = false }) => {
    const [showSendFeedback, setShowSendFeedback] = useState(false);
    const isPositive = useRef();

    const handleSendFeedback = useCallback((positive) => {
        isPositive.current = positive;
        setShowSendFeedback(true);
    }, [isPositive]);

    return <>
        {item.uuid && !hasFeedback && <div className="flex ml-auto gap-x-2">
            <button
                disabled={checkFeedbacks}
                onClick={() => handleSendFeedback(true)}
            >
                <HandThumbUpIcon
                    className={classNames("h-5 w-5 text-transparent stroke-blue-lightest", { "hover:fill-green-pastel": !checkFeedbacks })}
                />
            </button>
            <button
                disabled={checkFeedbacks}
                onClick={() => handleSendFeedback(false)}
            >
                <HandThumbDownIcon
                    className={classNames("h-5 w-5 text-transparent stroke-blue-lightest", { "hover:fill-orange-pastel": !checkFeedbacks })}
                />
            </button>
        </div>
        }
        <SendFeedbackModal
            show={showSendFeedback}
            onClose={() => setShowSendFeedback(false)}
            threadUid={item.thread_uuid}
            messageUid={item.uuid}
            isPositive={isPositive.current}
        />
    </>
}

const ShowMessageFeedback = ({ item }) => {
    const [showShowFeedback, setShowShowFeedback] = useState(false);

    const handleShowFeedback = useCallback(() => {
        setShowShowFeedback(true);
    }, []);

    return <div className="flex ml-auto gap-x-2">
        <button onClick={handleShowFeedback} disabled={!item.feedback_value}>
            <HandThumbUpIcon className={classNames("h-5 w-5 text-transparent stroke-blue-lightest", { "fill-green-pastel": item.feedback_value })} />
        </button>
        <button onClick={handleShowFeedback} disabled={item.feedback_value}>
            <HandThumbDownIcon className={classNames("h-5 w-5 text-transparent stroke-blue-lightest", { "fill-orange-pastel": !item.feedback_value })} />
        </button>
        <ShowFeedbackModal
            show={showShowFeedback}
            onClose={() => setShowShowFeedback(false)}
            isPositive={item.feedback_value}
            message={item.feedback_string}
        />
    </div>
}

const MessageFeedback = ({ item, hasFeedback = false, checkFeedbacks = false }) => {

    return <div className="w-full">
        <div id="feedback" className="flex w-full pt-1">
            {hasFeedback && <ShowMessageFeedback item={item} />}
            <SendMessageFeedback item={item} hasFeedback={hasFeedback} checkFeedbacks={checkFeedbacks} />
        </div>
        {checkFeedbacks && hasFeedback && <>
            <div className={classNames("flex w-full my-2", { "opacity-50": item.feedback_read })} >
                <p className={classNames("text-justify bg-blue-dark p-2 rounded w-full border-solid border-2", item.feedback_value ? "border-green-pastel" : "border-orange-pastel")}>
                    <span className="float-right h-full ml-2 flex items-end" style={{ "shapeOutside": "inset(calc(100% - 40px) 0 0)" }}>
                        <ArchiveFeedbackButton threadId={item.thread_uuid} messageId={item.uuid} archived={item.feedback_read} />
                    </span>
                    {item.feedback_string}
                </p>
            </div>
        </>
        }
    </div >
}

const Sources = ({ sources }) => {
    const [showSources, setShowSources] = useState(false);
    const [selectedSources, setSelectedSources] = useState(undefined);

    return <>
        {sources && sources.length > 0 && <>
            <BookOpenIcon
                title="Sources"
                className="w-4 h-4 stroke-blue-lightest hover:stroke-white cursor-pointer"
                onClick={() => {
                    setSelectedSources(sources)
                    setShowSources(true);
                }}
            />
            <ShowSources
                show={showSources}
                setShow={setShowSources}
                sources={selectedSources}
            />
        </>
        }
    </>
}

const MessageProgress = ({ className, progress = 0.5 }) => {
    const ref = useRef();

    useEffect(() => {
        const context = ref.current.getContext('2d');

        const a = 2 * Math.PI * progress;
        const x = 50 + Math.sin(a) * 50;
        const y = 50 - Math.cos(a) * 50;

        context.clearRect(0, 0, 100, 100);
        context.beginPath();
        context.moveTo(50, 50);
        context.lineTo(x, y);
        context.arc(50, 50, 50, a - Math.PI / 2., Math.PI * 1.5);
        context.closePath();

        context.fillStyle = "#000000D0";
        context.fill();
    }, [progress]);

    return <canvas
        ref={ref}
        width={100}
        height={100}
        alt="Progress ndicator"
        className={classNames(className, "w-8 h-8 rounded-full")}
    />
}

const ChatMessage = ({ userId, item, history, checkFeedbacks = false, editorRef }) => {
    const { data: user } = useGetUserDetailsQuery({ user_uuid: userId }, { skip: !userId });
    const collId = useCurrentCollectionId();
    const { data: modelPic } = useGetModelPicQuery({ collection_id: collId });
    const { data } = useGetModelNameQuery({ collection_id: collId });

    const senderIsUser = item.sender_type === SenderType.USER;
    const senderClassNamesFirstDiv = senderIsUser ? "justify-end" : "";
    const senderClassNamesSecondDiv = senderIsUser ? "order-1 items-end" : "order-2 items-start";
    const senderClassNamesMessage = senderIsUser ? "rounded-br-none pb-2" : "rounded-bl-none";
    const senderClassNamesImage = senderIsUser ? "order-2" : "order-1";

    const senderName = useMemo(() => {
        return data || item.sender
    }, [data, item.sender]);

    const userName = useMemo(() => checkFeedbacks ? `${user?.name}` : "You", [checkFeedbacks, user?.name])

    const hasFeedback = useMemo(() => !senderIsUser && !(item.feedback_value === null || item.feedback_value === undefined), [item.feedback_value, senderIsUser]);

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [deleteMessage] = useDeleteMessageMutation();
    const onConfirmDelete = useCallback((confirm) => {
        if (!confirm) return;
        const answer = history[history.indexOf(item) + 1];
        deleteMessage({ thread_uuid: item.thread_uuid, message_uuid: item.uuid });
        deleteMessage({ thread_uuid: answer.thread_uuid, message_uuid: answer.uuid });
    }, [deleteMessage, history, item]);

    const [killThread] = useKillThreadMutation();

    const steamEnded = !senderIsUser && (item.created || item.ended === true);

    const { data: dataAsTable } = useCopyAsTableQuery({ thread_uuid: item.thread_uuid, message_uuid: item.uuid }, { skip: !steamEnded });

    const avatarSrc = useMemo(() => senderIsUser ? user?.picture : modelPic, [modelPic, senderIsUser, user?.picture]);

    const canDelete = item.cmetadata?.can_delete ?? true;

    const getSelection = () => {
        console.log('window.get', document.getSelection().toString())
    }

    return <div className="chat-message px-2 pb-2">
        <div className={"flex items-end " + senderClassNamesFirstDiv}>
            <div className={classNames("relative flex flex-col space-y-2 text-sm max-w-[75%] mx-2", senderClassNamesSecondDiv, checkFeedbacks && hasFeedback ? "w-[75%]" : "max-w-[75%]")}>
                <div className={"px-4 pt-2 pb-1 rounded-lg inline-block bg-blue w-full relative group " + senderClassNamesMessage}>
                    {senderIsUser && canDelete &&
                        <div className="absolute right-full top-0 h-full flex items-center invisible group-hover:visible">
                            <TrashIcon className="w-6 h-6 mr-1 text-blue-light hover:text-orange cursor-pointer" onClick={() => setShowConfirmDelete(true)} />
                            <ConfirmationDialog show={showConfirmDelete} setShow={setShowConfirmDelete} onClose={onConfirmDelete} />
                        </div>
                    }
                    <div className="flex gap-x-4">
                        <p className={"flex-grow text-xs font-bold " + avatars[item.sender_type]?.textColor}>
                            {senderIsUser ? userName : senderName}
                        </p>
                        <div className="flex gap-x-1">
                            {steamEnded && <>
                                {item?.cmetadata?.sources && <Sources sources={item.cmetadata.sources} />}
                                {dataAsTable && <ClipboardDocumentListIcon
                                    title="Copy as Table"
                                    className="w-4 h-4 stroke-blue-lightest hover:stroke-white cursor-pointer"
                                    onClick={() => { navigator.clipboard.writeText(dataAsTable) }}
                                />}
                            </>}
                            {(steamEnded || senderIsUser) && <ClipboardDocumentIcon
                                title="Copy"
                                className="w-4 h-4 stroke-blue-lightest hover:stroke-white cursor-pointer"
                                //onClick={() => { navigator.clipboard.writeText(item.body) }}
                                onMouseDown={getSelection}
                            />}
                        </div>
                    </div>
                    {!item.body && <p className="font-thin text-sm italic">{senderName} is thinking...</p>}
                    <MarkdownMessage md={item.body} />
                    <div className="flex justify-between items-end">
                        {item.progress && item.progress.finished === false && <button
                            title="Cancel task"
                            className="text-xs text-blue-lightest -translate-x-3 hover:text-white"
                            onClick={() => killThread({ thread_uuid: item.thread_uuid })}
                        >
                            Cancel
                        </button>}
                        {!senderIsUser && <MessageFeedback item={item} hasFeedback={hasFeedback} checkFeedbacks={checkFeedbacks} />}
                    </div>
                </div>
            </div>
            <div className={classNames("relative", senderClassNamesImage)}>
                {avatarSrc
                    ? <img
                        src={avatarSrc}
                        alt={item.sender}
                        className="w-8 h-8 rounded-full"
                    />
                    : <Loading size="w-[32px] h-[32px]" stroke="border-[3px]" />
                }
                {item.progress && item.progress.finished === false && <>
                    <MessageProgress className="absolute top-0" progress={item.progress.value} />
                    <Loading className="absolute z-50 -top-[2px] -left-[2px]" size="w-[36px] h-[36px]" stroke="border-[3px]" />
                </>}
            </div>
        </div>
    </div>
}

const Banner = ({ item }) => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const [deleteMessage] = useDeleteMessageMutation();
    const onConfirmDelete = useCallback((confirm) => {
        if (!confirm) return;
        deleteMessage({ thread_uuid: item.thread_uuid, message_uuid: item.uuid });
    }, [deleteMessage, item]);

    const canDelete = item.cmetadata?.can_delete ?? true;

    return <div className="group w-full flex gap-x-2 my-2 px-2">
        {canDelete &&
            <div className="flex items-center invisible group-hover:visible">
                <TrashIcon className="w-6 h-6 text-blue-light hover:text-orange cursor-pointer" onClick={() => setShowConfirmDelete(true)} />
                <ConfirmationDialog show={showConfirmDelete} setShow={setShowConfirmDelete} onClose={onConfirmDelete} />
            </div>
        }
        <div className="flex-1 bg-blue border-yellow-pastel border-[1px] text-center rounded-md p-2">
            <MarkdownMessage md={item.body} />
        </div>
    </div>
}

const Message = ({ userId, item, history, checkFeedbacks, editorRef }) => {
    switch (item.sender_type) {
        case "banner": return <Banner item={item} />
        default: return <ChatMessage userId={userId} item={item} history={history} checkFeedbacks={checkFeedbacks} editorRef={editorRef} />
    }
}

export default function History({ uuid, feedbackThreadId, editorRef }) {
    const { data: chat } = useGetThreadFromUuidQuery({ thread_uuid: uuid }, { skip: !uuid })

    const collId = useCurrentCollectionId();
    const { data: feedbacks } = useGetCollectionFeedbackThreadQuery({ collection_id: collId, thread_uuid: feedbackThreadId }, { skip: !collId || !feedbackThreadId })

    const history = useMemo(() => chat?.messages || feedbacks?.messages || [], [chat, feedbacks]);
    const userId = useMemo(() => chat?.user_uuid || feedbacks?.user_uuid || "", [chat, feedbacks]);

    return <ScrollToBottom debounce={50} id="messages" className="flex-grow flex-shrink space-y-4 overflow-y-auto text-white">
        {history && history.map((item, idx) => <Message key={idx} userId={userId} item={item} history={history} checkFeedbacks={!!feedbackThreadId} editorRef={editorRef} />)}
    </ScrollToBottom>
}