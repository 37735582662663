import Buttoon from "components/Buttoon";
import { useEffect, useState, useCallback } from "react";
import { useCurrentCollectionId } from "state/GeneralSlice";
import { useFetchSettingQuery, useResetSettingMutation, useUpdateSettingMutation } from "state/api/settings";
import ConfirmationDialog from "../ConfirmationDialog";

function sanitizeEndpoint(endpoint) {
    let str = endpoint;
    while (str.includes("-")) {
        str = str.replace("-", "_");
    }
    return str;
}

export const RESET_VALUE = "\x1F";

//**************************************/
export function useFetchSetting(setting, value, setValue, onLoaded, additionalParams, additionalBody, overrideUrl) {
    const collId = useCurrentCollectionId();

    const { data, requestId } = useFetchSettingQuery({ collectionId: collId, path: setting.endpoint, params: additionalParams, body: additionalBody, overrideUrl: overrideUrl });
    useEffect(() => {
        if (data) {
            const fetched = data[sanitizeEndpoint(setting.endpoint)];
            if (value === RESET_VALUE || value === undefined) {
                //console.log("FETCH", setting.endpoint, fetched);
                setValue(fetched);
            }
        }
    }, [data, requestId, setValue, setting.endpoint, value]);

    useEffect(() => {
        if (data) onLoaded?.();
    }, [data, onLoaded]);
}

export function useSaveSettings(setting, save, value, onSaved, body = false, overrideUrl) {
    const collId = useCurrentCollectionId();
    const [isSaving, setSaving] = useState(false);
    const [update] = useUpdateSettingMutation();
    useEffect(() => {
        if (save) setSaving(true);
    }, [save, setSaving]);
    useEffect(() => {
        async function saveSetting() {
            if (isSaving) {
                const packet = {
                    collectionId: collId,
                    path: setting.endpoint,
                    overrideUrl: overrideUrl,
                }
                //console.log("SAVE", setting.endpoint, value);
                if (body) packet["body"] = value;
                else packet["payload"] = {
                    [sanitizeEndpoint(setting.endpoint)]: value
                };
                const result = await update(packet);
                const error = result.error ? setting.description : undefined;
                onSaved?.(error);
                setSaving(false);
            }
        }
        saveSetting();
    }, [body, collId, isSaving, onSaved, overrideUrl, setting.description, setting.endpoint, update, value]);

    return isSaving;
}

export function ResetSettingButton({ setting, onReset }) {
    const [show, setShow] = useState(false);
    const collId = useCurrentCollectionId();
    const [reset] = useResetSettingMutation();
    const handleConfirmation = useCallback((confirm) => {
        if (!confirm) return;
        onReset?.();
        reset({ collectionId: collId, path: setting.endpoint });
    }, [collId, onReset, reset, setting.endpoint]);

    return <div>
        <Buttoon
            padding="py-0"
            classNameOverride="bg-orange rounded-md h-6 text-xs align-middle py-0 hover:bg-orange-pastel w-28"
            onClick={() => setShow(true)}
        >
            Reset to Default
        </Buttoon>
        <ConfirmationDialog confirmText="Reset" show={show} setShow={setShow} onClose={handleConfirmation} />
    </div>
}

export function useFetchAndSaveSetting(setting, save, value, setValue, onLoaded, onSaved, body = false, additionalParams, additionalBody, overrideUrl) {
    const isLoaded = useFetchSetting(setting, value, setValue, onLoaded, additionalParams, additionalBody, overrideUrl);
    const isSaving = useSaveSettings(setting, save, value, onSaved, body, overrideUrl);

    return { isLoaded: isLoaded, isSaving: isSaving };
}