import { useDispatch } from "react-redux";
import CreateChat from "../chat/CreateChat";
import { useAsmCollectionId, useProdigyMpCollectionId } from "./totemUtils";
import { setCurrentCollectionId, useCurrentCollectionId } from "state/GeneralSlice";
import { useEffect } from "react";

function CreateTotemChat({ collectionId }) {
    const current = useCurrentCollectionId();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setCurrentCollectionId(collectionId));
    }, [collectionId, dispatch])

    return <>
        {(collectionId && collectionId === current) &&
            <CreateChat />
        }
    </>
}

export function CreateChatAsm() {
    const collectionId = useAsmCollectionId();
    return <CreateTotemChat collectionId={collectionId} />
}

export function CreateChatProdigyMp() {
    const collectionId = useProdigyMpCollectionId();
    return <CreateTotemChat collectionId={collectionId} />
}