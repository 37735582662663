import { useNewThreadCallback } from "../../../state/api/threads";
import { useEffect } from "react";
import Loading from "../Loading";
import { useCurrentCollectionId } from "state/GeneralSlice";

var creating = false;

export default function CreateChat() {
    const newThread = useNewThreadCallback();
    const collectionId = useCurrentCollectionId();
    useEffect(() => {
        async function create() {
            if (!creating) {
                creating = true;
                await newThread(collectionId);
                creating = false;
            }
        }
        create();
    }, [collectionId, newThread])
    return <Loading />
}
