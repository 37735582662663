import Loading from "../Loading";
import { useRef, useState, useEffect, useCallback } from "react";
import { useGetWorkingMemoryDataQuery, useUpdateWorkingMemoryDataMutation } from "state/api/collections";
import { useCurrentCollectionId } from "state/GeneralSlice";
import { ReactGrid } from "@silevis/reactgrid";
import "./ExcelVisualizer.scss"
import { TextAreaCellTemplate } from "./TextAreaCellTemplate";
import { StartChatTemplate } from "./StartChatCellTemplate";

export default function ExcelVisualizer() {
    const collId = useCurrentCollectionId();
    const { data, isLoading } = useGetWorkingMemoryDataQuery({ collection_id: collId });

    const cellsRef = useRef([]);
    const [initialized, setInitialized] = useState(false);
    const [rowHeights, setRowHeights] = useState([]);
    const onCellHeightChanged = useCallback((rowIdx, colIdx, height) => {
        if (!cellsRef.current[rowIdx]) {
            cellsRef.current[rowIdx] = new Array(6);
            cellsRef.current[rowIdx].fill(0);
        }

        const timeout = initialized ? 50 : 500;
        setInitialized(true);

        cellsRef.current[rowIdx][colIdx] = height;
        setTimeout(() => {
            setRowHeights(prev => {
                const tmp = [...prev];
                tmp[rowIdx] = Math.max(...cellsRef.current[rowIdx]);
                return tmp;
            })
        }, timeout);

    }, [initialized]);

    const [columns, setColumns] = useState();
    useEffect(() => {
        const c = [];
        for (let i = 0; i < 6; i++) {
            c.push({
                columnId: i,
                //width: 200,
                resizable: true,
            })
        }
        setColumns(c);
    }, [])

    const [rows, setRows] = useState();
    useEffect(() => {
        if (!data) setRows([]);
        else {
            setRows([
                {
                    rowId: "header",
                    cells: [
                        { type: "header", text: "A" },
                        { type: "header", text: "B" },
                        { type: "header", text: "C" },
                        { type: "header", text: "D" },
                        { type: "header", text: "E" },
                        { type: "header", text: "F" },
                    ]
                },

                ...data.map((el, idx) => ({
                    rowId: idx,
                    height: rowHeights[idx],
                    //height: 800,
                    cells: el.map((v, i) => ({
                        type: v.action === "create_thread" ? "startchat" : "textarea",
                        edit: false,
                        text: v.value,
                        style: {
                            padding: 0,
                        },
                        rowIdx: idx,
                        colIdx: i,
                        onHeightChanged: onCellHeightChanged,
                    })),
                })),

            ])
        }
    }, [data, onCellHeightChanged, rowHeights]);

    const handleColumnResize = (ci, width) => {
        setColumns((prevColumns) => {
            const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
            const resizedColumn = prevColumns[columnIndex];
            const updatedColumn = { ...resizedColumn, width };
            prevColumns[columnIndex] = updatedColumn;
            return [...prevColumns];
        });
    }

    const [updateWorkingMemory] = useUpdateWorkingMemoryDataMutation();
    const handleChanges = (changes) => {
        const paypload = changes.map(el => ({ row: el.rowId, col: el.columnId, value: el.newCell.text }));
        //updateWorkingMemory({ collection_id: collId, body: paypload });
    }

    const onFocusChanged = (change) => {

    }

    return <div className="p-2">
        <span className="text-3xl text-blue-lightest">Excel pieno di cose</span>
        <div className="mt-8">
            {isLoading
                ? <Loading />
                : <div id="spreadsheet" className="overflow-auto">
                    <ReactGrid
                        rows={rows}
                        columns={columns}
                        onColumnResized={handleColumnResize}
                        onCellsChanged={handleChanges}
                        onFocusLocationChanged={onFocusChanged}
                        customCellTemplates={{ textarea: new TextAreaCellTemplate(), startchat: new StartChatTemplate() }}
                    />
                </div>
            }
        </div>
    </div>
}