import { XMarkIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import React from "react";

type BadgeStyle =
    | "gray"
    | "red"
    | "yellow"
    | "green"
    | "azure"
    | "blue"
    | "purple"
    | "violet"
    | "pink"
    | "lime"
    | "aqua";

function getClassNamesFromColor(color: BadgeStyle): {
    background: string;
    text: string;
    hover: string;
} {
    switch (color) {
        case "red":
            return {
                background: "bg-[#c29489] ring-[#842813]",
                text: "text-[#842813]",
                hover: "hover:bg-[#842813] hover:text-[#c29489]"
            };
        case "yellow":
            return {
                background: "bg-[#eed9a4] ring-[#b18f3a]",
                text: "text-[#b18f3a]",
                hover: "hover:bg-[#b18f3a] hover:text-[#eed9a4]"
            };
        case "green":
            return {
                background: "bg-[#a0b398] ring-[#416631]",
                text: "text-[#416631]",
                hover: "hover:bg-[#416631] hover:text-[#a0b398]"
            };
        case "azure":
            return {
                background: "bg-[#99e9f2] ring-[#15aabf]",
                text: "text-[#15aabf]",
                hover: "hover:bg-[#15aabf] hover:text-[#99e9f2]"
            };
        case "blue":
            return {
                background: "bg-[#a5d8ff] ring-[#228be6]",
                text: "text-[#228be6]",
                hover: "hover:bg-[#228be6] hover:text-[#a5d8ff]"
            };
        case "purple":
            return {
                background: "bg-[#d0bfff] ring-[#7950f2]",
                text: "text-[#7950f2]",
                hover: "hover:bg-[#7950f2] hover:text-[#d0bfff]"
            };
        case "violet":
            return {
                background: "bg-[#eebefa] ring-[#be4bdb]",
                text: "text-[#be4bdb]",
                hover: "hover:bg-[#be4bdb] hover:text-[#eebefa]"
            };
        case "pink":
            return {
                background: "bg-[#fcc2d7] ring-[#e64980]",
                text: "text-[#e64980]",
                hover: "hover:bg-[#e64980] hover:text-[#fcc2d7]"
            };
        case "lime":
            return {
                background: "bg-[#b2f2bb] ring-[#2f9e44]",
                text: "text-[#2f9e44]",
                hover: "hover:bg-[#2f9e44] hover:text-[#b2f2bb]"
            };
        case "aqua":
            return {
                background: "bg-[#96f2d7] ring-[#099268]",
                text: "text-[#099268]",
                hover: "hover:bg-[#099268] hover:text-[#96f2d7]"
            };
        case "gray":
        default:
            return {
                background: "bg-[#e9ecef] ring-[#868e96]",
                text: "text-[#868e96]",
                hover: "hover:bg-[#868e96] hover:text-[#e9ecef]"
            };
    }
}

export default function Badge({
    text,
    src,
    color,
    isSelected
}: {
    text?: string;
    src?: string;
    color?: BadgeStyle;
    isSelected?: boolean;
}) {
    const { background, text: txt, hover } = getClassNamesFromColor(color);

    return (
        <span
            className={classNames(
                "inline-flex relative items-center gap-x-1 rounded-md px-2 py-1 text-xs font-medium ring-2 pointer-events-none",
                background,
                txt,
                { "ring-azure-pastel ring-[3px]": isSelected }
            )}
        >
            <span className="pointer-events-none">{text || "Badge"}</span>
            <button type="button" className="pointer-events-auto">
                <XMarkIcon className={classNames("w-3 h-3 rounded-sm", hover)} />
            </button>
            <div className="absolute bottom-full bg-white w-7 h-7">
                <img alt={text} src={src} className="w-4 z-[100]" />
            </div>
        </span>
    );
}
