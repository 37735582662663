export function objectToFormData<T>(input: T, ...exclude: Extract<keyof T, string>[]): FormData {
    const formData = new FormData();
    for (const k in input) {
        if (exclude.includes(k)) continue;
        formData.append(k, input[k] as string);
    }
    return formData;
}

export function stringToHash(string: string) {
    let hash = 0;

    if (string.length === 0) return hash;

    for (let i = 0; i < string.length; i++) {
        const ch = string.charCodeAt(i);
        hash = (hash << 5) - hash + ch;
        hash = hash & hash;
    }

    return hash;
}

export function getNameAndExtension(filename: string): { name: string; extension: string } {
    const idx = filename.lastIndexOf(".");
    return {
        name: filename.substring(0, idx),
        extension: filename.substring(idx + 1)
    };
}

export const nameof = <T>(name: Extract<keyof T, string>): string => name;

export function publicResource(file: string): string {
    return process.env.PUBLIC_URL + "/" + file;
}

export const BudgetTypes = {
    adv_query: {
        description: "Reasoning queries",
        unit: ""
    },
    base_query: {
        description: "Simplified reasoning queries",
        unit: ""
    },
    collections: {
        description: "Collections",
        unit: ""
    },
    embedding_kb: {
        description: "Embedding computation",
        unit: "MB"
    },
    storage_kb: {
        description: "Storage",
        unit: "MB"
    },
    stt_seconds: {
        description: "Speech-to-Text",
        unit: "seconds"
    },
    tts_characters: {
        description: "Text-to-Speech",
        unit: "characters"
    }
};

export function __build__parseBooleanEnvs(key: string, defaultValue: boolean) {
    const value = process.env[key];

    if(value === undefined) return defaultValue;

    const _checkingValue = value.toLowerCase().trim();
    return _checkingValue === "true" || _checkingValue === "1";
}
