// NOTE: all modules imported below may be imported from '@silevis/reactgrid'
import * as React from "react";
import {
    CellTemplate,
    Cell,
    Compatible,
    Uncertain,
    UncertainCompatible,
    isNavigationKey,
    getCellProperty,
    isAlphaNumericKey,
    keyCodes,
    getCharFromKey
} from "@silevis/reactgrid";
import { useMeasure } from "@uidotdev/usehooks";
import ReactTextareaAutosize from "react-textarea-autosize";

export interface TextAreaCell extends Cell {
    type: "textArea";
    text: string;
    placeholder?: string;
    validator?: (text: string) => boolean;
    errorMessage?: string;
    rowIdx: number;
    colIdx: number;
    onHeightChanged?: (rowIdx: number, colIdx: number, height: number) => void;
}

export class TextAreaCellTemplate implements CellTemplate<TextAreaCell> {
    private wasEscKeyPressed = false;

    getCompatibleCell(uncertainCell: Uncertain<TextAreaCell>): Compatible<TextAreaCell> {
        const text = getCellProperty(uncertainCell, "text", "string");
        let placeholder: string | undefined;
        try {
            placeholder = getCellProperty(uncertainCell, "placeholder", "string");
        } catch {
            placeholder = "";
        }
        const value = parseFloat(text); // TODO more advanced parsing for all text based cells
        const rowIdx = getCellProperty(uncertainCell, "rowIdx", "number");
        const colIdx = getCellProperty(uncertainCell, "colIdx", "number");
        return { ...uncertainCell, text, value, placeholder, rowIdx, colIdx };
    }

    update(cell: Compatible<TextAreaCell>, cellToMerge: UncertainCompatible<TextAreaCell>): Compatible<TextAreaCell> {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text, placeholder: cellToMerge.placeholder });
    }

    handleKeyDown(
        cell: Compatible<TextAreaCell>,
        keyCode: number,
        ctrl: boolean,
        shift: boolean,
        alt: boolean,
        key: string
    ): { cell: Compatible<TextAreaCell>; enableEditMode: boolean } {
        const char = getCharFromKey(key, shift);

        if (!ctrl && !alt && isAlphaNumericKey(keyCode) && !(shift && keyCode === keyCodes.SPACE))
            return { cell: this.getCompatibleCell({ ...cell, text: char }), enableEditMode: true };
        return { cell, enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER };
    }

    handleCompositionEnd(
        cell: Compatible<TextAreaCell>,
        eventData: any
    ): { cell: Compatible<TextAreaCell>; enableEditMode: boolean } {
        console.log("composition", eventData);
        return { cell: { ...cell, text: eventData }, enableEditMode: true };
    }

    getClassName(cell: Compatible<TextAreaCell>, isInEditMode: boolean): string {
        const isValid = cell.validator ? cell.validator(cell.text) : true;
        const className = cell.className ? cell.className : "";
        return `${isValid ? "valid" : "rg-invalid"} ${
            cell.placeholder && cell.text === "" ? "placeholder" : ""
        } ${className}`;
    }

    render(
        cell: Compatible<TextAreaCell>,
        isInEditMode: boolean,
        onCellChanged: (cell: Compatible<TextAreaCell>, commit: boolean) => void
    ): React.ReactNode {
        if (!isInEditMode) {
            const isValid = cell.validator ? cell.validator(cell.text) : true;
            const cellText = cell.text || cell.placeholder || "";
            const textToDisplay = !isValid && cell.errorMessage ? cell.errorMessage : cellText;
            return (
                <TextRenderer
                    text={textToDisplay}
                    rowIdx={cell.rowIdx}
                    colIdx={cell.colIdx}
                    onHeightChanged={cell.onHeightChanged}
                />
            );
        }

        return (
            <EditorRenderer
                rowIdx={cell.rowIdx}
                colIdx={cell.colIdx}
                onHeightChanged={cell.onHeightChanged}
                defaultValue={cell.text}
                onChange={(e: any) =>
                    onCellChanged(this.getCompatibleCell({ ...cell, text: e.currentTarget.value }), false)
                }
                onBlur={(e: any) => {
                    onCellChanged(
                        this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
                        !this.wasEscKeyPressed
                    );
                    this.wasEscKeyPressed = false;
                }}
                onCopy={(e: any) => e.stopPropagation()}
                onCut={(e: any) => e.stopPropagation()}
                onPaste={(e: any) => e.stopPropagation()}
                onPointerDown={(e: any) => e.stopPropagation()}
                placeholder={cell.placeholder}
                onKeyDown={(e: any) => {
                    if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode)) e.stopPropagation();
                    if (e.keyCode === keyCodes.ESCAPE) this.wasEscKeyPressed = true;
                }}
            />
        );
    }
}

function TextRenderer({
    text,
    rowIdx,
    colIdx,
    onHeightChanged
}: {
    text: string;
    rowIdx: number;
    colIdx: number;
    onHeightChanged: (rowIdx: number, colIdx: number, height: number) => void;
}) {
    const [ref, { height }] = useMeasure();
    React.useEffect(() => {
        onHeightChanged?.(rowIdx, colIdx, height);
    }, [colIdx, height, onHeightChanged, rowIdx]);

    return (
        <div className="h-full">
            <p ref={ref} className="whitespace-pre-wrap text-content p-1">
                {text}
            </p>
        </div>
    );
}

function EditorRenderer({ rowIdx, colIdx, onHeightChanged, ...props }: any) {
    const [ref, { height }] = useMeasure();

    React.useEffect(() => {
        onHeightChanged?.(rowIdx, colIdx, height);
    }, [colIdx, height, onHeightChanged, rowIdx]);

    const txtRef = React.useRef<HTMLTextAreaElement>();
    React.useEffect(() => {
        console.log("first", ref);
        const input = txtRef.current;
        input.focus();
        input.setSelectionRange(input.value.length, input.value.length);
    }, [ref]);

    return (
        <ReactTextareaAutosize
            className="p-1 resize-none overflow-hidden min-h-full w-full"
            ref={(input) => {
                ref(input);
                txtRef.current = input;
            }}
            {...props}
        />
    );
}
