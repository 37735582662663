import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { ArrowDownTrayIcon, PencilIcon, ChevronDownIcon, ChevronUpIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import SourceDownloadLink from "../chat/SourceDownloadLink";
import classNames from "classnames";
import { useCollectionPrivileges } from "../../../utils/Privileges";
import { useSetDocumentVisibilityMutation } from "../../../state/api/collections";
import { useCurrentCollectionId } from "../../../state/GeneralSlice";
import Loading from "../Loading";
import EditTitleModal from "./EditTitleModal";

function TableHeader({
    sortingProperty,
    setSortingProperty,
    sortingDescending,
    setSortingDescending,
    name,
    property,
    center = false,
    classes = ""
}) {
    const propertyToUse = property || name.toLowerCase();

    const onClick = useCallback((descending) => {
        setSortingProperty(propertyToUse);
        setSortingDescending(descending);
    }, [propertyToUse, setSortingDescending, setSortingProperty])

    return <th
        scope="col"
        className={"px-3 py-3.5 text-sm font-semibold text-blue-lightest " + classes}
    >
        <div className={"flex gap-x-2 " + (center ? "justify-center" : "")}>
            <span>{name}</span>
            <button>
                {propertyToUse !== sortingProperty
                    ? <ChevronUpIcon onClick={() => onClick(true)} className="h-5 stroke-blue hover:stroke-blue-light" />
                    : (
                        sortingDescending
                            ? <ChevronUpIcon onClick={() => onClick(!sortingDescending)} className="h-5 stroke-white" />
                            : <ChevronDownIcon onClick={() => onClick(!sortingDescending)} className="h-5 stroke-white" />
                    )
                }
            </button>
        </div>
    </th>
}


function VisibilityToggle({ docId, isInvisible }) {
    const collId = useCurrentCollectionId();
    const [setVisibility, { isLoading }] = useSetDocumentVisibilityMutation();

    return <>
        {isLoading
            ? <Loading size="w-4 h-4" center={false} />
            : <button
                className="flex items-center justify-center"
                onClick={() => setVisibility({ collection_id: collId, doc_uuid: docId, visibility: isInvisible })}>
                {isInvisible
                    ? <EyeSlashIcon className="w-5 text-transparent hover:text-blue-lightest stroke-blue-lightest hover:stroke-white" />
                    : <EyeIcon className="w-5 text-transparent hover:text-blue-lightest stroke-blue-lightest hover:stroke-white" />
                }
            </button >
        }
    </>
}

function TableRow({ documentList, selectedDocuments, setSelectedDocuments, document, onEditDocument }) {
    const { data: { writeAccess, adminAccess } } = useCollectionPrivileges();

    const alignActions = useMemo(() => {
        return documentList.filter((doc) => doc.filetype === "md").length > 0;
    }, [documentList]);

    const [edit, setEdit] = useState(false);

    return <tr key={document.filename} className={classNames("hover:bg-blue", { "opacity-50": document.invisible })}>
        <td>
            {/*selectedDocuments.includes(document) && (
                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
            )*/}
            <div className="flex items-center justify-center w-full">
                <input
                    type="checkbox"
                    className="h-4 w-4 rounded border-blue-light text-blue-light bg-blue-dark focus:ring-0"
                    value={document.name}
                    checked={selectedDocuments.includes(document)}
                    onChange={(e) =>
                        setSelectedDocuments(
                            e.target.checked
                                ? [...selectedDocuments, document]
                                : selectedDocuments.filter((d) => d !== document)
                        )
                    }
                />
            </div>
        </td>
        {adminAccess &&
            <td>
                <div className="flex items-center justify-center w-full" title="Visibility">
                    <VisibilityToggle docId={document.filename} isInvisible={document.invisible} />
                </div>
            </td>
        }
        <td className="whitespace-nowrap pl-0 pr-3 text-sm font-medium text-white">
            <p className="truncate">
                {document.title}
            </p>
            {edit && <EditTitleModal show={edit} setShow={setEdit} document={document} />}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-white text-center">
            {document.filetype}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-white text-center">
            {document.pages ? document.pages : "-"}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-white text-center">
            {document.chunks}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-azure-pastel text-center">
            <div className="flex items-center justify-center">
                <div className={classNames("flex gap-x-3", { "w-14 justify-start": alignActions })}>
                    {writeAccess &&
                        <button className="flex" title="Edit" onClick={() => { document.filetype === "note" ? onEditDocument(document) : setEdit(true) }}>
                            <PencilIcon className="h-5 w-full stroke-blue-lightest hover:stroke-white" />
                        </button>
                    }
                    <button className="flex" title="Download">
                        <SourceDownloadLink fileUuid={document.filename}>
                            <ArrowDownTrayIcon className="h-5 w-full stroke-blue-lightest hover:stroke-white" />
                        </SourceDownloadLink>
                    </button>
                </div>
            </div>
        </td>
    </tr >
}

export default function Table({
    documents,
    selectedDocuments,
    setSelectedDocuments,
    sortingProperty,
    setSortingProperty,
    sortingDescending,
    setSortingDescending,
    onEditDocument,
}) {
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const checkbox = useRef();
    const { data: { adminAccess } } = useCollectionPrivileges();

    useLayoutEffect(() => {
        const isChecked = selectedDocuments.length > 0 && selectedDocuments.length === documents.length;
        const isIndeterminate = selectedDocuments.length > 0 && selectedDocuments.length < documents.length;
        setChecked(isChecked);
        setIndeterminate(isIndeterminate);
        checkbox.current.indeterminate = isIndeterminate;
    }, [checkbox, documents.length, selectedDocuments.length]);

    const toggleAll = useCallback(() => {
        setSelectedDocuments(checked || indeterminate ? [] : documents);
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    }, [checked, documents, indeterminate, setSelectedDocuments]);


    return <div className="flex-grow flex-shrink overflow-y-auto w-full flow-root overflow-x-auto max-w-full min-w-full">
        <table className="min-w-full divide-y divide-blue-light">
            <colgroup>
                <col span={1} className="w-[25px] min-w-[25px]" />
                {adminAccess && <col span={1} className="w-[40px] min-w-[40px]" />}
            </colgroup>
            <thead className="bg-blue-dark">
                <tr>
                    <th>
                        <div className="flex items-center justify-center w-full">
                            <input
                                type="checkbox"
                                className="h-4 w-4 rounded border-blue-light text-blue-light bg-blue-dark focus:ring-0"
                                ref={checkbox}
                                checked={checked}
                                onChange={toggleAll}
                            />
                        </div>
                    </th>
                    {adminAccess &&
                        <th>
                            <div className="flex items-center justify-center w-full" title="Visibility">
                                <EyeIcon className="w-5 text-blue-lightest stroke-blue-light" />
                            </div>
                        </th>
                    }
                    <TableHeader
                        sortingProperty={sortingProperty}
                        setSortingProperty={setSortingProperty}
                        sortingDescending={sortingDescending}
                        setSortingDescending={setSortingDescending}
                        name='Title' classes='pl-0'
                    />
                    <TableHeader
                        sortingProperty={sortingProperty}
                        setSortingProperty={setSortingProperty}
                        sortingDescending={sortingDescending}
                        setSortingDescending={setSortingDescending}
                        name='Type' property="filetype" center={true}
                    />
                    <TableHeader
                        sortingProperty={sortingProperty}
                        setSortingProperty={setSortingProperty}
                        sortingDescending={sortingDescending}
                        setSortingDescending={setSortingDescending}
                        name='Pages' center={true}
                    />
                    <TableHeader
                        sortingProperty={sortingProperty}
                        setSortingProperty={setSortingProperty}
                        sortingDescending={sortingDescending}
                        setSortingDescending={setSortingDescending}
                        name='Chunks' center={true}
                    />
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-blue-lightest text-center"
                    >
                        Manage
                    </th>
                </tr>
            </thead>

            <tbody className="divide-y divide-blue-light">
                {documents.map((item, idx) => <TableRow
                    key={item.filename}
                    documentList={documents}
                    selectedDocuments={selectedDocuments}
                    setSelectedDocuments={setSelectedDocuments}
                    document={item}
                    onEditDocument={onEditDocument}
                />)}
            </tbody>
        </table>
    </div>
}