import { useCallback, useEffect, useMemo, useState } from "react"
import ProgressBar from "../ProgressBar";
import { removeTask, useDocumentTask } from "state/TasksSlice";
import classNames from "classnames";
import { TrashIcon, HandRaisedIcon, ArrowSmallUpIcon, ExclamationCircleIcon, CheckIcon, PencilIcon, XCircleIcon, NoSymbolIcon } from "@heroicons/react/24/solid";
import { useRemoveDocumentsInUploadMutation, useRemoveTaskInUploadMutation } from "state/api/collections";
import { useCurrentCollectionId } from "state/GeneralSlice";
import { useDispatch } from "react-redux";
import { useCollectionPrivileges } from "utils/Privileges";
import EnterAwareInput from "components/EnterAwareInput";

const FileState = {
    SELECTED: 0,
    UPLOADING: 1,
    ERROR: 2,
    REVOKED: 3,
    DONE: 4,
}

export default function TaskPoller({ idx, task, setFiles, uploading, onDelete }) {
    const documentId = task.document_id;
    const title = task.document_title;
    const type = task.document_type;

    const uploadingTask = useDocumentTask(documentId);

    const color = useMemo(() => {
        const status = uploadingTask.status;
        if (!status) return undefined;
        if (status === "REVOKED") return "bg-gray-dark";
        if (status === "ERROR") return "bg-orange";
        return undefined;
    }, [uploadingTask.status]);

    const isFailed = useMemo(() => uploadingTask.status === "ERROR", [uploadingTask.status]);

    const [state, setState] = useState();
    useEffect(() => {
        if (!uploadingTask?.status) {
            setState(FileState.SELECTED);
            return;
        }
        if (uploadingTask.status === "ERROR") {
            setState(FileState.ERROR);
            return;
        }
        if (uploadingTask.status === "REVOKED") {
            setState(FileState.REVOKED);
            return;
        }
        if (uploadingTask.status === "SUCCESS") {
            setState(FileState.DONE);
            return;
        }
        setState(FileState.UPLOADING);
    }, [uploadingTask.status]);

    const deleteFile = useCallback(() => {
        if (onDelete) onDelete(idx);
    }, [idx, onDelete]);

    const collId = useCurrentCollectionId();
    const [removeUpload] = useRemoveDocumentsInUploadMutation();
    const dispatch = useDispatch();
    const clearNotification = useCallback(() => {
        removeUpload({ collection_id: collId, doc_uid: documentId });
        dispatch(removeTask(documentId));
    }, [collId, dispatch, documentId, removeUpload])
    const [killTask] = useRemoveTaskInUploadMutation();

    const { data: { writeAccess } } = useCollectionPrivileges();

    //*********************
    const [edit, setEdit] = useState(false);

    const submitTitle = useCallback((title) => {
        if (!setFiles) return;
        const t = title || "-";
        setEdit(false);
        setFiles((prev) => {
            const tmp = [...prev];
            tmp[idx].document_title = t;
            return tmp;
        });
    }, [idx, setFiles]);

    return <div className={classNames(uploading ? "" : "mb-5")} >
        <div className="flex items-center justify-between gap-x-5 text-xs text-white">
            {edit
                ? <div className="flex items-center flex-grow">
                    <span>{idx + 1} -</span>
                    <EnterAwareInput
                        name="title"
                        id="title"
                        defaultValue={title}
                        className={classNames("border-0 focus:ring-0 bg-blue w-full flex-1 px-1 text-xs rounded-md")}
                        placeholder="Title"
                        onEnterPressed={submitTitle}
                        autofocus
                    />
                    <span>.{type}</span>
                </div>
                : <p className={classNames("flex-grow py-2 truncate block")}>
                    {idx + 1} - {title}.{type}
                </p>
            }

            {state === FileState.SELECTED ?
                <div className="flex gap-x-1">
                    <button title="Rename" onClick={() => setEdit(true)}>
                        <PencilIcon className="w-5 h-5 text-blue-light hover:text-white" />
                    </button>
                    <button title="Delete" onClick={() => deleteFile()}>
                        <TrashIcon className="w-5 h-5 text-orange-pastel hover:text-orange" />
                    </button>
                </div>
                : <div className="flex gap-x-1">
                    {state === FileState.UPLOADING && <ArrowSmallUpIcon className="w-5 h-5 text-blue-lightest" />}
                    {state === FileState.DONE && <CheckIcon className="w-5 h-5 text-green-pastel" />}
                    {state === FileState.REVOKED && <NoSymbolIcon className="w-5 h-5 text-yellow-pastel" />}
                    {state === FileState.ERROR && <ExclamationCircleIcon className="w-5 h-5 text-orange-pastel" />}
                    {state === FileState.DONE
                        ? <></>
                        : <>
                            {writeAccess && <>
                                {uploadingTask.completed
                                    ? <button title="Clear" onClick={clearNotification}>
                                        <XCircleIcon className="w-5 h-5 text-blue-lightest hover:text-white" />
                                    </button>
                                    : <button title="Kill" onClick={() => killTask({ collection_id: collId, doc_uid: documentId })}>
                                        <HandRaisedIcon className="w-5 h-5 text-orange-pastel hover:text-orange" />
                                    </button>
                                }
                            </>}
                        </>
                    }
                </div>
            }
        </div>
        {!uploading &&
            <ProgressBar
                value={uploadingTask.progress}
                max={1.}
                showPerc={true}
                showNumber={!isFailed}
                description={uploadingTask.status}
                height="h-4"
                color={color}
                transition={!isFailed}
            />
        }
    </div>
}