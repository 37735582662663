import { useFetchAndSaveSetting } from "./settingsUtils";
import { useCallback } from "react";

function ChoiceCheckbox({ current, value, setValue }) {
    const updateSelection = useCallback((e) => {
        if (e.target.checked) setValue(prev => [...prev, current]);
        else setValue(prev => prev.filter(v => v !== current));
    }, [current, setValue])

    return <div className="w-1/2 flex gap-x-1 items-center">
        <input
            name={current}
            id={current}
            type="checkbox"
            checked={value.includes(current)}
            onChange={updateSelection}
            className="h-4 w-4 rounded border-blue-light text-blue-light bg-blue-dark focus:ring-0"
        />
        <label htmlFor="prompt" className="font-normal text-xs truncate pr-2">
            {current}
        </label>
    </div>
}

export default function SettingMultipleChoice({ setting, save, value, setValue, onLoaded, onSaved }) {
    const choices = setting.values_list;

    useFetchAndSaveSetting(setting, save, value, setValue, onLoaded, onSaved, true);

    return <div className="w-full">
        <label htmlFor="prompt" className="block text-sm font-medium leading-6 mb-2">
            {setting.description}
        </label>
        <div className="flex flex-wrap gap-y-1">
            {choices.map((c, idx) => <ChoiceCheckbox key={idx} current={c} value={value} setValue={setValue} />)}
        </div>
    </div>
}