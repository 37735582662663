import { ChatBubbleLeftIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { Thread } from "./Thread";
import { useNavigate } from "react-router-dom";
import { UseSortedThreads } from "../../../state/api/threads";
import { useCurrentCollectionId } from "../../../state/GeneralSlice";

function ThreadsList({ title, items }) {
    return <>
        {(items && items.length > 0) && <>
            <div className="flex items-center gap-x-2 mr-2">
                <div className="border-t border-blue flex-1"></div>
                <div className="text-sm text-blue-light">{title}</div>
            </div>
            {items.map((element, index) =>
                <Thread key={element.uuid} element={element} />
            )}
        </>
        }
    </>
}

export function Threads() {
    const navigate = useNavigate();
    const collIdd = useCurrentCollectionId();
    const { data: threads } = UseSortedThreads(collIdd);

    return <div className="flex flex-col h-full">
        <div className="text-blue-lightest flex justify-between p-2 text-sm leading-6 font-semibold select-none">
            <button className="flex items-center gap-x-3 hover:text-white" onClick={() => navigate("chat/new")} title="New Chat">
                <ChatBubbleLeftIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                <span>New Chat</span>
            </button>
            <button className="flex items-center gap-x-3 hover:text-white" onClick={() => navigate("chat")}>
                <InformationCircleIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
            </button>
        </div>

        <div className="relative h-full">
            <div className="absolute ml-2 overflow-auto bottom-0 top-0 left-0 right-0">
                <ThreadsList title="Today" items={threads.today} />
                <ThreadsList title="Yesterday" items={threads.yesterday} />
                <ThreadsList title="Last Week" items={threads.lastWeek} />
                <ThreadsList title="Older" items={threads.older} />
            </div>
        </div>
    </div>
}