import { emptyApi as api } from "./emptyApi";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import service from "../../utils/AccessTokenService";
import _ from "lodash";
import { currentCollectionIdSelector } from "../GeneralSlice";
import { CollectionIdArgs } from "./collections";

const threadsRtkApi = api.enhanceEndpoints({ addTagTypes: ["threads"] }).injectEndpoints({
    endpoints: (build) => ({
        getThreads: build.query<TheadDescription[], undefined>({
            query: () => ({
                url: `/threads`
            }),
            providesTags: [{ type: "threads", id: "LIST" }]
        }),
        getThreadsByCollection: build.query<TheadDescription[], CollectionIdArgs>({
            query: (args) => ({
                url: `/threads/by-collection/${args.collection_id}`
            }),
            transformResponse: (response: any[]) => {
                response.forEach((t) => {
                    t["thread_created"] = t["thread-created"];
                    t["last_message"] = t["last-message"];
                    delete t["thread-created"];
                    delete t["last-message"];
                    t.last_interaction = t.last_message || t.thread_created;
                });
                return response;
            },
            providesTags: [{ type: "threads", id: "LIST" }]
        }),
        getThreadFromUuid: build.query<ThradInfo, ThreadIdArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads/${queryArg.thread_uuid}`
                };
            },
            providesTags: (result, error, arg) => [{ type: "history", id: arg.thread_uuid }]
        }),
        newThread: build.mutation<NewThreadResult, NewThreadArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads`,
                    method: "POST",
                    params: queryArg
                };
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const tmpUuid = "tmp-" + Math.random();
                const patchResult = dispatch(
                    threadsRtkApi.util.updateQueryData("getThreads", undefined, (draft: any[] | undefined) => {
                        if (draft) {
                            draft.push({ title: arg.title, uuid: tmpUuid });
                        }
                    })
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: [{ type: "threads", id: "LIST" }]
        }),
        updateThreadTitle: build.mutation<any, UpdateThreadTitleArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads/${queryArg.uuid}/title`,
                    method: "POST",
                    params: {
                        title: queryArg.title
                    }
                };
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    threadsRtkApi.util.updateQueryData("getThreadFromUuid", { thread_uuid: arg.uuid }, (draft) => {
                        draft.title = arg.title;
                    })
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: [{ type: "threads", id: "LIST" }]
        }),
        suggestThreadTitle: build.query<string, SuggestThreadTitleArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads/${queryArg.thread_uuid}/title-suggestion`,
                    params: { current_org_uuid: queryArg.current_org_uuid }
                };
            }
        }),
        deleteThread: build.mutation<any, DeleteThreadArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads/${queryArg.uuid}`,
                    method: "DELETE"
                };
            },
            onQueryStarted: (arg, { dispatch, queryFulfilled, getState }) => {
                const collId = currentCollectionIdSelector(getState());
                const patchResult = dispatch(
                    threadsRtkApi.util.updateQueryData(
                        "getThreadsByCollection",
                        { collection_id: collId },
                        (draft: any[] | undefined) => {
                            if (draft) {
                                return draft.filter((el) => el.uuid !== arg.uuid);
                            }
                        }
                    )
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: [{ type: "threads", id: "LIST" }]
        }),
        ask: build.mutation<any, AskArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads/${queryArg.thread_uuid}/ask`,
                    method: "POST",
                    body: {
                        question: queryArg.payload.question,
                        active_index: "inputs",
                        use_filtering: false
                    }
                };
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    threadsRtkApi.util.updateQueryData(
                        "getThreadFromUuid",
                        { thread_uuid: arg.thread_uuid },
                        (draft) => {
                            draft.messages.push({ sender: "You", sender_type: "user", body: arg.payload.question });
                        }
                    )
                );
                try {
                    const result = await queryFulfilled;

                    dispatch(
                        threadsRtkApi.util.updateQueryData(
                            "getThreadFromUuid",
                            { thread_uuid: arg.thread_uuid },
                            (draft) => {
                                // Update Query UUID
                                draft.messages[draft.messages.length - 1] = {
                                    ...draft.messages[draft.messages.length - 1],
                                    uuid: result?.data?.query_uuid,
                                    thread_uuid: arg.thread_uuid
                                };

                                // Update Answer UUID && Sources!
                                draft.messages.push({
                                    sender: "Vicuña",
                                    sender_type: "llm",
                                    body: result?.data?.answer,
                                    uuid: result?.data?.answer_uuid,
                                    thread_uuid: arg.thread_uuid,
                                    cmetadata: {
                                        sources: result?.data?.sources
                                    }
                                });
                            }
                        )
                    );
                } catch {
                    patchResult.undo();

                    /**
                     * Alternatively, on failure you can invalidate the corresponding cache tags
                     * to trigger a re-fetch:
                     * dispatch(api.util.invalidateTags(['Post']))
                     */
                }
            },
            invalidatesTags: [{ type: "threads", id: "LIST" }]
            // invalidatesTags: (result, error, arg) =>
            //     [{type: "threads", id: arg.uuid}],
            // invalidatesTags: ["files"]
        }),
        stream: build.mutation<any, AskArgs>({
            queryFn: async (arg, { dispatch, getState }) => {
                const patchesToUndo = [];

                const patchResult = dispatch(
                    threadsRtkApi.util.updateQueryData(
                        "getThreadFromUuid",
                        { thread_uuid: arg.thread_uuid },
                        (draft) => {
                            draft.messages.push({
                                thread_uuid: arg.thread_uuid,
                                sender: "You",
                                sender_type: "user",
                                body: arg.payload.question
                            });
                        }
                    )
                );
                patchesToUndo.push(patchResult);

                try {
                    const patchResult = dispatch(
                        threadsRtkApi.util.updateQueryData(
                            "getThreadFromUuid",
                            { thread_uuid: arg.thread_uuid },
                            (draft) => {
                                draft.messages.push({
                                    thread_uuid: arg.thread_uuid,
                                    sender: "Vicuña",
                                    sender_type: "llm",
                                    ended: false
                                });
                            }
                        )
                    );
                    patchesToUndo.push(patchResult);
                    const state: any = getState();
                    const collId = state.general.currentCollectionId;
                    const token = await service.getAccessToken();
                    const params = new URLSearchParams({ current_org_uuid: arg.current_org_uuid });
                    const response = await fetch(
                        process.env.REACT_APP_API_URL + `/threads/${arg.thread_uuid}/stream?` + params,
                        {
                            method: "POST",
                            mode: "cors",
                            cache: "no-cache",
                            credentials: "same-origin",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`
                            },

                            body: JSON.stringify({
                                question: arg.payload.question,
                                // history: history ? history : [],
                                // FIXME: Active index?
                                active_index: collId,
                                use_filtering: false
                            })
                        }
                    );
                    // response.body is a ReadableStream
                    if (!response.ok) throw new Error(response.type);

                    const reader = response.body.getReader();
                    for await (const jsons of readJSON(reader)) {
                        for (const json of jsons) {
                            const jsonAnswer = json["answer"];
                            const jsonSources = json["sources"];
                            const questionUuid = json["query_uuid"];
                            const answerUuid = json["answer_uuid"];

                            _.unset(json, "answer");
                            _.unset(json, "sources");
                            _.unset(json, "query_uuid");
                            _.unset(json, "answer_uuid");

                            const patchResult = dispatch(
                                threadsRtkApi.util.updateQueryData(
                                    "getThreadFromUuid",
                                    { thread_uuid: arg.thread_uuid },
                                    (draft) => {
                                        if (questionUuid) {
                                            const questionMessage = draft.messages[draft.messages.length - 2];
                                            _.set(questionMessage, "uuid", questionUuid);
                                        }

                                        const answerMessage = draft.messages[draft.messages.length - 1];
                                        _.merge(answerMessage, json);
                                        if (jsonAnswer) {
                                            let new_body = answerMessage.body
                                                ? answerMessage.body + jsonAnswer
                                                : jsonAnswer;

                                            // if <clear/> in newbody, remove everything before it
                                            const clearIndex = new_body.lastIndexOf("<clear/>");
                                            if (clearIndex !== -1) {
                                                new_body = new_body.substring(clearIndex + 8);
                                            }

                                            const progIdx = new_body.indexOf("<prog ");
                                            if (progIdx !== -1) {
                                                const closeIdx = new_body.indexOf("/>", progIdx);
                                                const tag = new_body.substring(progIdx, closeIdx) + " />";

                                                _.set(answerMessage, "progress", {
                                                    value: Number(readAttribute(tag, "prog")),
                                                    finished: readAttribute(tag, "end") === "true"
                                                });

                                                new_body = new_body.substring(closeIdx + 2);
                                            }
                                            _.set(answerMessage, "body", new_body);
                                        }
                                        if (answerUuid) {
                                            _.set(answerMessage, "uuid", answerUuid);
                                        }
                                        if (jsonSources) {
                                            _.merge(answerMessage, {
                                                cmetadata: {
                                                    sources: jsonSources
                                                }
                                            });
                                        }
                                    }
                                )
                            );
                            patchesToUndo.push(patchResult);
                        }
                    }
                    const streamEndedPatch = dispatch(
                        threadsRtkApi.util.updateQueryData(
                            "getThreadFromUuid",
                            { thread_uuid: arg.thread_uuid },
                            (draft) => {
                                const answerMessage = draft.messages[draft.messages.length - 1];
                                _.set(answerMessage, "ended", true);
                            }
                        )
                    );
                    patchesToUndo.push(streamEndedPatch);
                    return { data: {} };
                } catch (e) {
                    for (let i = patchesToUndo.length - 1; i >= 0; i--) {
                        patchesToUndo[i].undo();
                    }
                    return { error: e.message };
                }
            },
            invalidatesTags: [{ type: "threads", id: "LIST" }]
        }),
        deleteMessage: build.mutation<any, DeleteMessageArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads/${queryArg.thread_uuid}/${queryArg.message_uuid}`,
                    method: "DELETE"
                };
            },
            onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    threadsRtkApi.util.updateQueryData(
                        "getThreadFromUuid",
                        { thread_uuid: arg.thread_uuid },
                        (draft: ThradInfo) => {
                            if (draft) {
                                const history = draft.messages.filter((el) => el.uuid !== arg.message_uuid);
                                return { ...draft, messages: history };
                            }
                        }
                    )
                );
                queryFulfilled.catch(patchResult.undo);
            },
            invalidatesTags: (result, error, arg) => [{ type: "history", id: arg.thread_uuid }]
        }),
        killThread: build.mutation<any, ThreadIdArgs>({
            query: (args) => ({
                url: `/threads/${args.thread_uuid}/kill`,
                method: "POST"
            })
        }),
        provideFeedback: build.mutation<any, ProvideFeedbackArgs>({
            query: (queryArg) => {
                return {
                    url: `/threads/${queryArg.thread_uuid}/${queryArg.message_uuid}/feedback`,
                    method: "POST",
                    params: {
                        feedback_value: queryArg.feedback_value,
                        feedback_string: queryArg.feedback_string
                    }
                };
            },
            invalidatesTags: ["feedbacks"],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    threadsRtkApi.util.updateQueryData(
                        "getThreadFromUuid",
                        { thread_uuid: arg.thread_uuid },
                        (draft) => {
                            if (draft.messages) {
                                draft.messages.forEach((element: any) => {
                                    if (element.uuid !== arg.message_uuid) return;
                                    element.feedback_value = arg.feedback_value;
                                    element.feedback_string = arg.feedback_string;
                                });
                            }
                        }
                    )
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            }
        }),
        copyAsTable: build.query<any, DeleteMessageArgs>({
            query: (args) => {
                return {
                    url: `/threads/${args.thread_uuid}/${args.message_uuid}/copy-as-table`
                };
            }
        })
    }),
    overrideExisting: false
});

export default threadsRtkApi;

//*****************/
function readAttribute(input: string, name: string) {
    const idx = input.indexOf(name + "=");
    if (idx === -1) return 0;
    const start = input.indexOf("=", idx);
    const value = input.substring(start + 1, input.indexOf(" ", start));
    return value;
}

function parseMultipleJson(string: string) {
    let start = string.indexOf("{");
    let open = 0;
    let isString = false;
    const res = [];
    for (let i = start; i < string.length; i++) {
        if (!isString && string[i] === "{") {
            open++;
            if (open === 1) {
                start = i;
            }
        } else if (!isString && string[i] === "}") {
            open--;
            if (open === 0) {
                res.push(JSON.parse(string.substring(start, i + 1)));
                start = i + 1;
            }
        } else if (string[i] === '"' && (i < 2 || string[i - 1] !== "\\")) {
            isString = !isString;
        }
    }
    return { jsonsInString: res, tail: open > 0 ? string.substring(start) : "" };
}

export function readJSON(reader: ReadableStreamDefaultReader) {
    return {
        async *[Symbol.asyncIterator]() {
            let readResult = await reader.read();
            let stringBuffer = "";
            while (!readResult.done) {
                const string = String.fromCharCode(...readResult.value);
                const { jsonsInString, tail } = parseMultipleJson(stringBuffer ? stringBuffer + string : string);
                stringBuffer = tail;
                yield jsonsInString;
                readResult = await reader.read();
            }
        }
    };
}
//*****************/
class SortedThreads {
    today: TheadDescription[] = [];
    yesterday: TheadDescription[] = [];
    lastWeek: TheadDescription[] = [];
    older: TheadDescription[] = [];
    empty: boolean = true;
}

export function UseSortedThreads(collectionId: string) {
    const response = useGetThreadsByCollectionQuery({ collection_id: collectionId }, { skip: !collectionId });
    const st = useMemo(() => {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        const todayTime = today.getTime() / 1000;

        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayTime = yesterday.getTime() / 1000;

        const lastweek = new Date(today);
        lastweek.setDate(lastweek.getDate() - 7);
        const lastWeekTime = lastweek.getTime() / 1000;

        const threads = response.data ? [...response.data] : [];
        threads.sort((a, b) => b.last_interaction - a.last_interaction);
        const sorted: SortedThreads = new SortedThreads();
        sorted.empty = threads.length === 0;
        threads.forEach((t) => {
            if (t.last_interaction >= todayTime) sorted.today.push(t);
            else if (t.last_interaction >= yesterdayTime) sorted.yesterday.push(t);
            else if (t.last_interaction >= lastWeekTime) sorted.lastWeek.push(t);
            else sorted.older.push(t);
        });
        return sorted;
    }, [response.data]);

    return { ...response, data: st };
}

//*****************/
export type ThreadIdArgs = {
    thread_uuid: string;
};

export type SuggestThreadTitleArgs = ThreadIdArgs & {
    current_org_uuid: string;
};

export type TheadDescription = {
    uuid: string;
    title: string;
    thread_created: number;
    last_message: number;
    last_interaction: number;
};

export type MessageInfo = {
    uuid: string;
    thread_uuid: string;
    sender: string;
    sender_type: string;
    created: number;
    body: string;
    cmetadata: {
        sources: [];
    };
    feedback_value: null;
    feedback_string: null;
};

export type ThradInfo = TheadDescription & {
    user_uuid: string;
    created: number;
    messages: any[];
    collection_uuid: string;
};

export type AskPayload = {
    question: string;
    history: Record<string, string>[];
};

export type NewThreadArgs = {
    title: string;
    collection_uuid: string;
};

export type NewThreadResult = {
    uuid: string;
};

export type UpdateThreadTitleArgs = {
    uuid: string;
    title: string;
    return_not_authenticated?: boolean;
};

export type DeleteThreadArgs = {
    uuid: string;
};

export type AskArgs = {
    thread_uuid: string;
    current_org_uuid: string;
    payload: AskPayload;
};

export type DeleteMessageArgs = {
    thread_uuid: string;
    message_uuid: string;
};

export type ProvideFeedbackArgs = {
    thread_uuid: string;
    message_uuid: string;
    feedback_value: boolean;
    feedback_string: string;
    return_not_authenticated?: boolean;
};

export const DEFAULT_TITLE = "Untitled";

export const useNewThreadCallback = () => {
    const navigate = useNavigate();
    const [trigger] = useNewThreadMutation();
    return useCallback(
        async (collectionId: string, template: string | undefined) => {
            const result = await trigger({ title: DEFAULT_TITLE, collection_uuid: collectionId });
            if ("data" in result) {
                const newChatUUID = result.data?.uuid;
                if (!newChatUUID) return;
                navigate("../" + newChatUUID, template ? { state: template } : undefined);
            } else {
                // Error!
            }
        },
        [navigate, trigger]
    );
};

export const {
    useGetThreadFromUuidQuery,
    useGetThreadsByCollectionQuery,
    useNewThreadMutation,
    useLazySuggestThreadTitleQuery,
    useUpdateThreadTitleMutation,
    useDeleteThreadMutation,
    useAskMutation,
    useStreamMutation,
    useDeleteMessageMutation,
    useKillThreadMutation,
    useProvideFeedbackMutation,
    useCopyAsTableQuery
} = threadsRtkApi;

export class useLazyStreamQuery {}
