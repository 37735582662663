import classNames from "classnames";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { TrashIcon } from "@heroicons/react/24/solid";
import { PencilIcon } from "@heroicons/react/20/solid";
import { useOrganizationPrivileges } from "utils/Privileges";
import { useDeleteCollectionListMutation } from "state/api/collections";
import { setCurrentCollectionId } from "state/GeneralSlice";
import { loadCollectionId } from "utils/Session";
import NewCollectionModal from "./NewCollectionModal";
import ConfirmationDialog from "../ConfirmationDialog";

export default function ManageCollection({ collection, organizationId, fromPrivileges = false }) {
    const { data: { isAdmin } } = useOrganizationPrivileges();

    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const requestEdit = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowEdit(true);
    }, []);
    const requestDelete = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowDelete(true);
    }, []);

    const [deleteCollection] = useDeleteCollectionListMutation();
    const dispatch = useDispatch();
    const deleteCallback = useCallback((confirm) => {
        if (!confirm) return;
        deleteCollection({ collection_id: collection.uuid }).then((response) => {
            if (!response.error) {
                dispatch(setCurrentCollectionId(undefined));
                loadCollectionId();
            }
        })
    }, [collection.uuid, deleteCollection, dispatch]);

    return <>
        {isAdmin
            ? <div className="flex items-center gap-x-1">
                <button title="Edit">
                    <PencilIcon
                        className={classNames(
                            "fill-blue-lightest hover:fill-yellow-pastel h-4 w-4 shrink-0",
                            { "invisible group-hover:visible": !fromPrivileges }
                        )}
                        onClick={requestEdit}
                    />
                </button>
                {showEdit && <NewCollectionModal show={showEdit} setShow={setShowEdit} organizationId={organizationId} collectionId={collection.uuid} editPrivileges={!organizationId} />}
                <button title="Delete">
                    <TrashIcon
                        className={classNames(
                            "fill-blue-lightest hover:fill-orange h-4 w-4 shrink-0",
                            { "invisible group-hover:visible": !fromPrivileges }
                        )}
                        onClick={requestDelete}
                    />
                </button>
                {showDelete && <ConfirmationDialog show={showDelete} setShow={setShowDelete} onClose={deleteCallback} />}
            </div >
            : <></>
        }
    </>
}