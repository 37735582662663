import { configureStore } from '@reduxjs/toolkit';
import { emptyApi } from "./api/emptyApi";
import generalReducer, { generalSlice } from "./GeneralSlice";
import tasksReducer, { tasksSlice } from './TasksSlice';

const store = configureStore({
    reducer: {
        [generalSlice.name]: generalReducer,
        [tasksSlice.name]: tasksReducer,
        [emptyApi.reducerPath]: emptyApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(emptyApi.middleware),
});

export default store;