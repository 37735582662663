import React, { useEffect, useState } from "react";
import _ from "lodash";

type SetterCallback = (newValue: any) => void;
class AccessTokenService {
    private _accessTokenFn: Function;
    private _accessToken: string | undefined;

    setAccessTokenFn(fn: Function) {
        this._accessTokenFn = fn;
        // Init access token state
        this.getAccessToken();
    }

    updateAccessToken(newValue: string | undefined) {
        if (_.isEqual(newValue, this._accessToken))
            return;
        this._accessToken = newValue;
        if (!this._observers)
            return;
        this._observers.forEach((update) => update(() => this._accessToken));
    }

    async getAccessToken(...args: any[]): Promise<any> {
        const newToken = await this._accessTokenFn(...args);
        this.updateAccessToken(newToken);
        return newToken;
    }

    getCurrentAccessToken(): string | undefined {
        return this._accessToken;
    }

    private _observers: React.Dispatch<React.SetStateAction<any>>[];

    addObserver(stateSetterCallback: SetterCallback) {
        if (!this._observers) {
            this._observers = [];
        }
        this._observers.push(stateSetterCallback);
    }

    removeObserver(stateSetterCallback: SetterCallback) {
        if (!this._observers) {
            return;
        }
        this._observers = this._observers.filter((fn) => fn !== stateSetterCallback);
    }
}

const service = new AccessTokenService();

const useToken = () => {
    const [token, setToken] = useState(service.getCurrentAccessToken())
    useEffect(() => {
        service.addObserver(setToken);
        setToken(service.getCurrentAccessToken())
        return () => service.removeObserver(setToken);
    }, [setToken])
    return token
}

export {
    useToken
}

export default service;