import { useEffect } from "react";

export default function SettingAlert({ setting, save, value, setValue, onLoaded, onSaved }) {
    useEffect(() => onLoaded?.(), [onLoaded]);

    useEffect(() => {
        if (save) {
            onSaved?.();
        }
    }, [onSaved, save]);

    return <div className="h-full w-full">
        <div className="bg-yellow relative rounded-md p-2">
            <label className="block text-center text-blue-dark text-sm font-medium leading-6">
                {setting.description}
            </label>
        </div>
    </div>
}