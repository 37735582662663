import { useCurrentCollectionId } from "state/GeneralSlice";
import { useGetHomescreenTypeQuery } from "state/api/collections";
import NewChat from "./NewChat";
import Loading from "../Loading";
import ExcelVisualizer from "./ExcelVisualizer";

export default function Homescreen() {
    const collId = useCurrentCollectionId();
    const { data } = useGetHomescreenTypeQuery({ collection_id: collId }, { skip: !collId });

    switch (data) {
        case "excel": return <ExcelVisualizer />
        case "chat": return <NewChat />
        default: <Loading />
    }
}