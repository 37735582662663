import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { ComponentsMap } from "./SettingComponentesMap";
import classNames from "classnames";

const useBuildComponent = (props) => {
    const [builtComponent, setBuiltComponent] = useState(<></>);

    const { setValues, setting, onLoad, onSave, onValid, idx } = props;

    const setValue = useCallback((v) => {
        setValues(prev => {
            //console.log("SET", setting.endpoint, v);
            const tmp = _.cloneDeep(prev);
            const set = tmp.find(el => el.endpoint === setting.endpoint);
            let value;
            if (typeof v === "function") value = v(set.value);
            else value = v;
            set.value = value;
            return tmp;
        });
    }, [setValues, setting.endpoint]);

    const onLoaded = useCallback(() => {
        onLoad(idx);
    }, [idx, onLoad])

    const onSaved = useCallback((error) => {
        onSave(idx, error)
    }, [idx, onSave]);

    const setValid = useCallback((value) => {
        onValid(idx, value);
    }, [idx, onValid])

    useEffect(() => {
        const Component = ComponentsMap[props.setting.type];
        if (Component) setBuiltComponent(<Component {...props} setting={setting} setValue={setValue} onLoaded={onLoaded} onSaved={onSaved} setValid={setValid} />);
        else setBuiltComponent(<></>);
    }, [onLoaded, onSaved, props, setValid, setValue, setting]);

    return builtComponent;
}

export default function SettingComponent(props) {
    const component = useBuildComponent(props);

    return <div className={classNames(props.setting.type, props.className)}>
        {component}
    </div>
}