import classNames from "classnames";
import style from "./MarkdownMessage.module.css";
import { useEffect, useState } from "react";
import { convertHtmlToReact } from "components/content/markdown/MarkdownUtils";

export default function RawMarkdownMessage({ content, className, source, ...props }) {
    const [component, setComponent] = useState();
    useEffect(() => {
        setComponent(convertHtmlToReact(content, source));
    }, [content, source]);
    return (
        <span className={style.markdown} {...props}>
            <div
                className={classNames(className, { 'whitespace-pre-wrap': !!source })}
            // strange as it sounds, this seems best practice ¯\_(ツ)_/¯
            //dangerouslySetInnerHTML={{ __html: content }}
            >
                {component}
            </div>
        </span>
    );
}