import { useNavigate, useParams } from "react-router-dom";
import Chat from "../Chat";
import Buttoon from "components/Buttoon";
import { useCurrentCollectionId } from "state/GeneralSlice";
import { useAsmCollectionId } from "./totemUtils";
import { useMemo } from "react";
import { ReactComponent as Logo } from "./directout.svg";

const ChatType = {
    ASM: 0,
    PRODIGY_MP: 1,
}

export default function TotemChat() {
    const { threadId } = useParams();
    const collectionId = useCurrentCollectionId();
    const asmId = useAsmCollectionId();
    const navigate = useNavigate();
    const type = useMemo(() => collectionId === asmId ? ChatType.ASM : ChatType.PRODIGY_MP, [asmId, collectionId]);
    const text = useMemo(() => type === ChatType.ASM ? "ASM" : "PRODIGY.MP support", [type]);
    const path = useMemo(() => type === ChatType.ASM ? "../asm" : "../prodigymp", [type]);

    return <div className="h-screen flex flex-col max-w-7xl mx-auto">
        <div className="text-white flex items-center justify-between border-b border-gray py-2 px-4 bg-blue">
            <div className="font-bold">You are chatting with DirectOut {text} AI</div>
            <div className="flex gap-x-5">
                <Buttoon classNameOverride="bg-blue-light rounded-md align-middle text-sm py-0 hover:bg-blue-lightest w-32 h-9" onClick={() => navigate(path)}>
                    Clear Chat
                </Buttoon>
                <Buttoon classNameOverride="bg-orange rounded-md align-middle text-sm py-0 hover:bg-orange-pastel w-32" onClick={() => navigate("../")}>
                    Close Chat
                </Buttoon>
            </div>
        </div>
        <div className="flex-grow overflow-y-auto mt-3 relative">
            <Logo className="absolute bottom-1/2 left-0 right-0 mx-auto opacity-[10%] w-[75%] fill-blue-dark stroke-black" />
            <Chat threadId={threadId} />
        </div>
    </div>
}