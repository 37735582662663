import { useCallback, useEffect, useMemo, useState } from "react";
import AddDocumentModal from "./AddDocumentModal";
import AddNoteModal from "./AddNoteModal";
import Buttoon from "../../Buttoon";
import AddWebUrlModal from "./AddWebUrlModal";
import AddVoiceModal from "./AddVoiceModal";
import { useCurrentCollectionId } from "../../../state/GeneralSlice";
import { useCollectionPrivileges } from "../../../utils/Privileges";
import ConfirmationDialog from "../ConfirmationDialog";
import { CogIcon } from "@heroicons/react/24/outline";
import TasksModal from "../tasks/TasksModal";
import { useAddCollectionListDocumentMutation, useGetCollectionListsQuery, useGetDocumentsInUploadQuery, useRemoveCollectionListDocumentMutation } from "state/api/collections";
import classNames from "classnames";
import { useGetTaskResultQuery } from "state/api/tasks";
import { useDispatch } from "react-redux";
import { updateTaskStatus, useDocumentTask, useWorkInProgress } from "state/TasksSlice";
import Modal from "../Modal";
import ComboBox from "components/ComboBox";

const MAX_RETRY = 5;

function TaskPoller({ documentId, taskId }) {
    const [pollingInterval, setPollingInterval] = useState(2000);
    const [retries, setRetries] = useState(0);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isProblem, setIsProblem] = useState(false);
    const [isRevoked, setIsRevoked] = useState(false);

    const task = useDocumentTask(documentId);

    useEffect(() => {
        if (task.completed) {
            const timeout = setTimeout(() => setPollingInterval(0.0), 2000);
            return () => clearTimeout(timeout);
        }
    }, [task.completed]);

    const { data, isError, requestId } = useGetTaskResultQuery({ jid: taskId }, {
        skip: !taskId,
        pollingInterval: pollingInterval,
        refetchOnMountOrArgChange: true,
    });

    const isFailed = useMemo(() => retries >= MAX_RETRY, [retries]);
    const dispatch = useDispatch();

    const updateProgress = useCallback((progress) => {
        dispatch(updateTaskStatus({ documentId: documentId, progress: progress }));
    }, [dispatch, documentId]);

    useEffect(() => {
        if (taskId) dispatch(updateTaskStatus({ documentId: documentId, taskId: taskId }));
    }, [dispatch, documentId, taskId])

    useEffect(() => {
        if (isFailed) {
            updateProgress(1.0);
            setPollingInterval(0);
            if (!isProblem) {
                setIsProblem(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFailed]);

    useEffect(() => {
        if (isError || !data || data?.status === "FAILURE") {
            setRetries((prev) => prev + 1);
            return;
        }

        if (data?.status === "STARTED") {
            updateProgress(0.0);
            return;
        }

        if (data?.status === "REVOKED") {
            setPollingInterval(0);
            updateProgress(1.0);
            if (!isRevoked) {
                setIsRevoked(true);
            }
        }

        if (data?.status === "SUCCESS") {
            setPollingInterval(0);
            updateProgress(1.0);
            if (!isSuccess) {
                const timer = setTimeout(() => {
                    setIsSuccess(true);
                }, 1000);
                return () => clearTimeout(timer)
            }
        }

        if (isError || !data) {
            setRetries((prev) => prev + 1);
        }
        else {
            if (data.meta) updateProgress(data.meta.total_progress);
            setRetries(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isError, requestId])

    useEffect(() => {
        function getStatus() {
            if (isRevoked) return "REVOKED";
            if (isSuccess) return "SUCCESS";
            if (isFailed) return "ERROR";
            if (!requestId) return "UPLOADING";
            if (!data?.status) return "WAITING";
            return data?.status === "FAILURE" ? "RETRYING..." : data?.status
        }
        dispatch(updateTaskStatus({ documentId: documentId, status: getStatus() }));
    }, [data?.status, dispatch, documentId, isFailed, isRevoked, isSuccess, requestId]);

    return <></>
}

function AddToList({ show, setShow, selectedDocuments }) {
    const collId = useCurrentCollectionId();
    const { data } = useGetCollectionListsQuery({ collection_id: collId });
    const items = useMemo(() => data ? data.map((l) => l.uuid) : [], [data]);
    const descriptions = useMemo(() => data ? data.map((l) => l.title) : [], [data]);

    const [selectedList, setSelectedList] = useState();
    const [addToList] = useAddCollectionListDocumentMutation();
    const handleSubmit = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        selectedDocuments.forEach(element => {
            addToList({ collection_id: collId, list_id: selectedList, document_id: element.filename });
        });
        setShow(false);
    }, [addToList, collId, selectedDocuments, selectedList, setShow]);

    return <Modal show={show}>
        <form onSubmit={handleSubmit} className="w-[500px]">
            <p className="text-xl lg:text-2xl mb-1 mt-3 text-blue-lightest">Add Documents To List</p>
            <div className="rounded-lg border-0 border-dashed border-blue-lightest shadow-sm text-white z-50 h-full">
                <ComboBox
                    name="list"
                    label="Select a list"
                    items={items}
                    descriptions={descriptions}
                    onSelected={setSelectedList}
                />
            </div>

            <div className="flex items-center justify-end mt-2 gap-x-2">
                <Buttoon
                    type="submit"
                    width="w-16"
                    disabled={!selectedList}
                >
                    Add
                </Buttoon>
                <Buttoon
                    type="button"
                    onClick={() => setShow(false)}
                    width="w-16"
                    className="shrink-0"
                >
                    Cancel
                </Buttoon>
            </div>
        </form>
    </Modal>
}

export default function ControlBar({ selectedDocuments, setSelectedDocuments, deleteFile, editDocument, setEditDocument, selectedList }) {
    const collId = useCurrentCollectionId();

    const clearSelection = useCallback(() => {
        setSelectedDocuments([]);
    }, [setSelectedDocuments]);

    const deleteDocuments = useCallback((confirm) => {
        if (!confirm) return;
        Promise
            .all(selectedDocuments.map((element) => deleteFile({ collection_id: collId, file_id: element.filename })))
            .then((r) => clearSelection());
    }, [clearSelection, collId, deleteFile, selectedDocuments]);

    const [removeDocument] = useRemoveCollectionListDocumentMutation();
    const removeFromList = useCallback((confirm) => {
        if (!confirm) return;
        Promise
            .all(selectedDocuments.map((element) => removeDocument({ collection_id: collId, list_id: selectedList, document_id: element.filename })))
            .then((r) => clearSelection());
    }, [clearSelection, collId, removeDocument, selectedDocuments, selectedList]);

    const [addDocumentActive, setAddDocumentActive] = useState(false)
    const [addNoteActive, setAddNoteActive] = useState(false)
    const [addVoiceActive, setAddVoiceActive] = useState(false)
    const [addWebUrlActive, setAddWebUrlActive] = useState(false)

    const { data: { writeAccess } } = useCollectionPrivileges();
    const [showAddToList, setShowAddToList] = useState(false);
    const [showRemoveFromList, setShowRemoveFromList] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [showTasks, setShowTasks] = useState(false);
    const { data } = useGetDocumentsInUploadQuery({ collection_id: collId }, { pollingInterval: 10000, refetchOnMountOrArgChange: true });
    const tasks = useMemo(() => data || [], [data]);

    const isWorking = useWorkInProgress();

    return <div className="sm:flex sm:items-center">
        <div className="flex w-full justify-between pr-1 sm:pr-8">
            <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-white">Knowledge Base</h1>
                <p className="mt-2 text-sm text-blue-lightest">
                    Manage your knowledge base
                </p>
            </div>
            <button onClick={() => setShowTasks(true)}>
                <CogIcon className={classNames("h-8 w-8", { "animate-spin-slow": isWorking })} />
            </button>
            {showTasks && <TasksModal show={showTasks} setShow={setShowTasks} tasks={tasks} />}
            {tasks.map((el) => <TaskPoller key={el.document_id} documentId={el.document_id} taskId={el.task_id} />)}
        </div>
        <div className="mt-4 inline-flex sm:mt-0 sm:flex-none gap-2">
            {selectedDocuments.length > 0
                ? (
                    <>
                        {writeAccess && <>
                            <Buttoon
                                onClick={() => setShowAddToList(true)}
                                disabled={selectedDocuments.length === 0}
                            >
                                Add To List
                            </Buttoon>
                            <AddToList show={showAddToList} setShow={setShowAddToList} selectedDocuments={selectedDocuments} />
                            <Buttoon
                                onClick={() => setShowRemoveFromList(true)}
                                disabled={!selectedList}
                            >
                                Remove From List
                            </Buttoon>
                            <ConfirmationDialog confirmText="Remove" show={showRemoveFromList} setShow={setShowRemoveFromList} onClose={removeFromList} />
                            <Buttoon
                                onClick={() => setShowConfirmation(true)}
                                disabled={selectedDocuments.length === 0}
                                className="bg-orange hover:bg-orange-pastel"
                            >
                                Delete
                            </Buttoon>
                            <ConfirmationDialog show={showConfirmation} setShow={setShowConfirmation} onClose={deleteDocuments} />
                        </>
                        }
                    </>
                )
                : (
                    writeAccess && <>
                        <Buttoon onClick={() => setAddDocumentActive(true)} >Add Document</Buttoon>
                        {addDocumentActive && <AddDocumentModal show={addDocumentActive} onClose={() => setAddDocumentActive(false)} />}

                        <Buttoon onClick={() => setAddNoteActive(true)} >Add Text Note</Buttoon>
                        {editDocument && <AddNoteModal editDocument={editDocument} onClose={() => setEditDocument()} />}
                        {addNoteActive && <AddNoteModal onClose={() => setAddNoteActive(false)} />}

                        <Buttoon onClick={() => setAddVoiceActive(true)} >Add Voice Note</Buttoon>
                        {addVoiceActive && <AddVoiceModal show={addVoiceActive} onClose={() => setAddVoiceActive(false)} />}

                        {/*<Buttoon onClick={() => setAddWebUrlActive(true)} >Add Web URL</Buttoon>*/}
                        {/*{addWebUrlActive && <AddWebUrlModal show={addWebUrlActive} onClose={() => setAddWebUrlActive(false)} /> }*/}
                    </>
                )
            }
        </div>
    </div>
}