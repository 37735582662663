import classNames from "classnames";
import { useMemo, useState } from "react";
import SettingComponent from "./SettingsComponent";
import { useMediaQuery } from "@uidotdev/usehooks";
import ComboBox from "components/ComboBox";
import { SettingContainerWrapper } from "./SettingsContainer";

function TabsCombobox({ tabs, selectedTab, onSelected }) {
    const items = useMemo(() => tabs.map((el, idx) => idx), [tabs]);
    const titles = useMemo(() => tabs.map(el => el.description), [tabs]);

    return <div className="flex w-full items-center justify-between gap-x-1">
        <div className={classNames("w-full")}>
            <ComboBox
                label=""
                items={items}
                descriptions={titles}
                initialValue={selectedTab}
                onSelected={onSelected}
            />
        </div>
    </div>
}

function TabsCarousel({ tabs, selectedTab, onSelected }) {
    return <div className="block">
        <div className="">
            <nav className="flex" aria-label="Tabs">
                {tabs.map((tab, idx) => (
                    <button
                        key={idx}
                        type="button"
                        onClick={() => onSelected(idx)}
                        className={classNames(
                            selectedTab === idx
                                ? 'border-white text-white text-base'
                                : 'border-transparent text-blue-lightest hover:text-white',
                            'w-full py-2 border-b-2 px-1 text-center text-xs'
                        )}
                    >
                        {tab.description}
                    </button>
                ))}
            </nav>
        </div>
    </div>
}

function TabsRender({ contents, isReady, isLoading, onChange, onLoaded, isInputValid, onValid, state, setState, getValue, isSaving, onSaved, className, selectedTab, setSelectedTab, onStateChanged, ...props }) {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return <div>
        {isMobile
            ? <TabsCombobox tabs={contents} selectedTab={selectedTab} onSelected={(tab) => (setSelectedTab(tab))} />
            : <TabsCarousel tabs={contents} selectedTab={selectedTab} onSelected={(tab) => (setSelectedTab(tab))} />
        }

        {isReady && <div className={classNames("w-full h-full px-2 pt-3 pb-2", { "hidden": isLoading }, className)}>
            {contents.map((setting, idx) =>
                <SettingComponent
                    className={classNames({ "hidden": isLoading || idx !== selectedTab })}
                    key={idx}
                    setting={setting}
                    value={getValue(setting.endpoint)}
                    save={isSaving}
                    onChange={onChange}
                    isValid={isInputValid[idx] === undefined ? true : isInputValid[idx]}
                    allCurrentValues={state} //todo

                    idx={idx}
                    setValues={setState}
                    onLoad={onLoaded}
                    onSave={onSaved}
                    onValid={onValid}
                />
            )}
        </div>}
    </div>
}

export function SettingTabsContainer(props) {
    const description = props.setting.description;
    const [selectedTab, setSelectedTab] = useState(0);

    return <div className="h-full w-full">
        <div className="mb-2">
            <label htmlFor="prompt" className="block text-sm font-medium leading-6">
                {description}
            </label>
            <div className="bg-gray-darkest rounded-md">
                <SettingContainerWrapper {...props} Renderer={TabsRender} others={{
                    selectedTab: selectedTab,
                    setSelectedTab: setSelectedTab
                }} />
            </div>
        </div>
    </div>
}