import { useState, useEffect } from "react";
import RawMarkdownMessage from "./RawMarkdownMessage";
import { processMarkdown } from "components/content/markdown/MarkdownUtils";

export default function MarkdownMessage({ md, className, source }) {
    const [processedBody, setProcessedBody] = useState();

    // this looks like I can actually use react,
    // but Copilot did it for me, please check!
    useEffect(() => {
        processMarkdown(md).then((value) => setProcessedBody(value));
    }, [md]);

    return <RawMarkdownMessage content={processedBody} className={className} source={source} />
}
